import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from "#app"
import { useAccountStore } from "@/stores/account"
import axios from "@evercam/shared/api/client/axios"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { camelizeKeys } from "humps"

export default defineNuxtRouteMiddleware(async (to) => {
  const accountStore = useAccountStore()

  if (!accountStore.token && !to.fullPath?.includes("/v2/users/signin")) {
    accountStore.redirectUrl = to.fullPath
  }

  const {
    apiId,
    apiKey,
    widgetId,
    widgetKey,
    isWidget,
    impersonationToken,
    authorization,
    keycloak,
    idpHint,
    demo,
    forceApiLogin,
  } = camelizeKeys(to.query) as Record<string, string>
  accountStore.isLiveViewWidget = to.path?.includes("live-view")
  const routeCameraExid = (camelizeKeys(to.params) as Record<string, string>)
    ?.cameraExid
  accountStore.isBimCompareWidget = to.path?.includes("embedded-bim-compare")
  if (authorization) {
    if (accountStore.token && !useNuxtApp().nuxt2Context.$device.isIos) {
      await accountStore.logout({ redirect: false })
    }
    accountStore.token = authorization
    accountStore.$persist()
    axios.setToken(authorization)
    accountStore.cleanUrlCredentials()
  }
  if (to.path.includes("/v2/projects/") && to.path.includes("/archives")) {
    const splitted = to.path.split("/")
    const projectExid = splitted[3]
    const mediaExid = splitted[6]
    const cameraExid = splitted[4]

    if ((authorization || accountStore.token) && mediaExid) {
      try {
        await EvercamApi.mediaHub.cShow(projectExid, mediaExid)
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t("content.media_hub.not_found"),
        })

        return navigateTo({ path: `/v2/projects/${projectExid}/media-hub` })
      }
    } else {
      if (mediaExid) {
        return navigateTo(
          `/v2/projects/${projectExid}/media-hub/${mediaExid}?camera_exid=${cameraExid}`
        )
      } else {
        return navigateTo(
          `/v2/projects/${projectExid}/media-hub?camera_exid=${cameraExid}`
        )
      }
    }
  }

  if (demo === "true") {
    accountStore.isDemo = true
  }

  if (keycloak == "true") {
    accountStore.isUsingKeycloak = true
    accountStore.idpHint = idpHint
  }

  if (isWidget || forceApiLogin) {
    const isPrivate = to.query.private === "true"
    if (isPrivate && (!apiId || !apiKey) && !accountStore.token) {
      return navigateTo("/v2/users/signin")
    }
    if (apiId && apiKey) {
      await accountStore.apiLogin({ apiId, apiKey })
    } else if (widgetId && widgetKey) {
      await accountStore.widgetLogin({ widgetId, widgetKey })
    }
    accountStore.isWidget = true

    return
  } else {
    accountStore.isWidget = !!to.query.sidebar
  }

  if (
    !useNuxtApp().nuxt2Context.$auth.getPersistedToken() &&
    useNuxtApp().nuxt2Context.$auth.isValidSsoAuthRedirectedUrl()
  ) {
    try {
      await accountStore.login()

      return
    } catch (_) {
      const sourceUrlRequestedSso = window.localStorage?.getItem(
        "sourceUrlRequestedSso"
      )
      window.localStorage?.removeItem("sourceUrlRequestedSso")

      return navigateTo(sourceUrlRequestedSso)
    }
  }

  const axiosConfig = axios.defaults.headers.common
  const axiosToken = (axiosConfig?.Authorization as string)?.replace(
    "Bearer ",
    ""
  )
  if (axiosToken && !useNuxtApp().nuxt2Context.$auth.getPersistedToken()) {
    accountStore.token = axiosToken
  }

  if (accountStore.token) {
    if (
      useNuxtApp().nuxt2Context.$auth.isTokenExpired() &&
      !useNuxtApp().nuxt2Context.$device.isIos
    ) {
      await accountStore.logout({ revoke: false })

      return {}
    }

    if (useNuxtApp().nuxt2Context.$auth.isDoubleLogin()) {
      await accountStore.logout({ redirect: false })
    }
  }

  if (to.path.indexOf("undefined") !== -1) {
    return navigateTo("/")
  }

  if (impersonationToken) {
    await accountStore.impersonationLogin()
  }

  if (to.path === "/v2/users/signout") {
    await accountStore.logout({ redirect: false })
    accountStore.zohoLogout()
    window.location.href = "https://support.evercam.io"
  }

  //redirected to sign in when non logged in user access non public pages
  const isPublicPage = to.meta[0]?.public
  if (
    !accountStore.token &&
    !isPublicPage &&
    !routeCameraExid &&
    !useNuxtApp().nuxt2Context.$auth.isValidSsoAuthRedirectedUrl()
  ) {
    return navigateTo("/v2/users/signin")
  }
})
