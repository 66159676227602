
// NEW WIDGET SYSTEM
import WidgetPreview from "@/components/widgets/WidgetPreview"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default {
  components: {
    WidgetPreview,
  },
  layout: "widget",
  async asyncData({ redirect }) {
    try {
      return {
        widget: await EvercamApi.widgets.getByKeys(),
      }
    } catch (error) {
      console.error(error)
      redirect("/error")

      return
    }
  },
}
