
import RecordingsWidget from "@/components/recordings/widget/RecordingsWidget"
import BimCompareContainer from "@/components/bimCompare/BimCompareContainer"
import VideoWallGallery from "@/components/videoWalls/VideoWallGallery"
import { WidgetType, RessourceByType } from "@evercam/shared/types"
import { VideoWallItemType } from "@evercam/shared/types/videoWall"
import { useAccountStore } from "@/stores/account"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import { useRecordingsStore } from "@/stores/recordings"
import { useVideoWallStore } from "@/stores/videoWall"
import { useBimCompareStore } from "@/stores/bimCompare"
import { mapStores } from "pinia"

export default {
  components: {
    RecordingsWidget,
    BimCompareContainer,
    VideoWallGallery,
  },
  props: {
    widget: {
      type: Object,
      default: null,
    },
    isEmbedded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attributes: {},
      component: null,
    }
  },
  computed: {
    ...mapStores(
      useCameraStore,
      useAccountStore,
      useRecordingsStore,
      useProjectStore,
      useVideoWallStore,
      useBimCompareStore
    ),
  },
  watch: {
    widget: {
      handler: "setWidget",
      deep: true,
    },
  },
  mounted() {
    this.setWidget()
    if (this.isEmbedded) {
      this.$setTimeout(this.initiatContainer, 500)
    }
  },
  methods: {
    async setWidget() {
      if (!this.widget || !this.isRessourceValid()) {
        this.component = "div"

        return
      }

      switch (this.widget.widgetType) {
        case WidgetType.LiveWidget:
          this.accountStore.isLiveViewWidget = true
          this.accountStore.isRecordingWidget = false
          this.recordingsStore.selectedTimestamp = undefined

          this.component = "RecordingsWidget"
          this.attributes = {
            key: this.widget.ressources.camera.exid,
            cameraId: this.widget.ressources.camera?.exid ?? "",
            refreshRate: (this.widget.settings.refresh ?? 5) * 1000,
            allowPlayPause: this.widget.settings.playpause ?? false,
            allowZoom: this.widget.settings.zoom ?? false,
            allowFullscreen: this.widget.settings.fullscreen ?? false,
            allowDownload: this.widget.settings.download ?? false,
            isSideBar: false,
            isEmbedded: this.isEmbedded,
          }
          break
        case WidgetType.Recording:
          await this.cameraStore.selectCamera(
            this.widget.ressources.camera?.exid
          )
          this.accountStore.isLiveViewWidget = false
          this.accountStore.isRecordingWidget = true
          this.recordingsStore.selectedTimestamp = this.$moment()
            .tz(this.cameraStore.selectedCamera.timezone)
            .startOf("hour")
            .format("YYYY-MM-DDTHH:mm:ssZ")

          this.component = "RecordingsWidget"
          this.attributes = {
            key: this.widget.ressources.camera.exid,
            cameraId: this.widget.ressources.camera.exid,
            delayBy: this.delayMilliseconds(),
            isDarkMode: this.widget.settings.darkMode ?? false,
            isSideBar:
              this.widget.settings.calendar ??
              this.widget.settings.sidebar ??
              false,
            isEmbedded: this.isEmbedded,
          }
          break
        case WidgetType.BimCompare:
          await this.cameraStore.selectCamera(
            this.widget.ressources.camera?.exid
          )
          this.bimCompareStore.isWidgetPreview = !this.isEmbedded
          this.bimCompareStore.isSidebarPreview = !this.isEmbedded
          this.recordingsStore.selectedTimestamp = this.$moment()
            .tz(this.cameraStore.selectedCamera.timezone)
            .startOf("hour")
            .format("YYYY-MM-DDTHH:mm:ssZ")

          this.component = "BimCompareContainer"
          this.attributes = {
            key: this.widget.ressources.camera?.exid,
            selectedCamera: this.cameraStore.selectedCamera,
            isDarkMode: this.widget.settings.darkMode ?? false,
            isTimelinePage: !(this.widget.settings.timeline ?? false),
            isEmbedded: this.isEmbedded,
          }
          break
        case WidgetType.VideoWall:
          if (this.isEmbedded) {
            await this.projectStore.fetchUserProjects()
          }

          await this.projectStore.selectProject(
            this.widget.ressources.project?.exid
          )
          const items = this.projectStore.selectedProjectCameras.map((c) => ({
            value: c,
            type: VideoWallItemType.LiveView,
          }))
          this.videoWallStore.preset = this.widget.settings.preset?.reduce(
            (acc, item) => {
              const camera = items.find(
                (el) => el.value.exid === item.camera
              )?.value

              if (camera) {
                acc.push({
                  ...item,
                  camera: { ...camera },
                })
              }

              return acc
            },
            []
          )

          this.component = "VideoWallGallery"
          this.attributes = {
            key: this.widget.ressources.project?.exid,
            items: items,
            preset: this.videoWallStore.preset,
            refreshRate: this.widget.settings?.refreshRate ?? 50_000,
            showSettings: false,
            isPreview: !this.isEmbedded,
          }
          break
      }
    },
    isRessourceValid() {
      return (
        (RessourceByType.camera.includes(this.widget.widgetType) &&
          this.widget?.ressources?.camera &&
          this.widget?.ressources?.camera?.length !== 0) ||
        (RessourceByType.project.includes(this.widget.widgetType) &&
          this.widget?.ressources?.project &&
          this.widget?.ressources?.project?.length !== 0)
      )
    },
    delayMilliseconds() {
      if (!this.widget.settings.hasDelay) {
        return 0
      }

      return this.$moment
        .duration(this.widget.settings.delay, this.widget.settings.delayUnit)
        .asMilliseconds()
    },
    initiatContainer() {
      try {
        let url =
          window.location != window.parent.location
            ? document.referrer
            : document.location.href
        window.parent.postMessage(
          {
            type: "ready",
            width: `${this.widget.settings.width}${this.widget.settings.resolution}`,
            height:
              this.widget.settings.height &&
              this.widget.settings.heightResolution
                ? `${this.widget.settings.height}${this.widget.settings.heightResolution}`
                : "400px",
          },
          url
        )
      } catch (e) {
        console.log(e)
      }
    },
  },
}
