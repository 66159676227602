
import Vue from "vue"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"
import NdaIcon from "@/components/NdaIcon"

export default Vue.extend({
  name: "ProjectsAutocomplete",
  components: {
    Autocomplete,
    NdaIcon,
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
    },
    filterProjects: {
      type: Function,
      default: (project) => project,
    },
    required: {
      type: Boolean,
      default: true,
    },
    showProjectDetails: {
      type: Boolean,
      default: false,
    },
    selectFirst: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItems: null,
    }
  },
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
    filteredProjects() {
      return this.filterProjects(this.projectStore.projects)
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.selectedItems = value
        }
      },
      immediate: true,
      deep: true,
    },
    selectedItems: {
      handler(value) {
        if (value) {
          this.$emit("input", value)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (this.selectFirst) {
      this.selectedItems = this.projectStore.projects?.[0]
    }
  },
  methods: {
    remove(item) {
      const index = this.selectedItems.indexOf(item?.id)
      if (index > -1) {
        this.selectedItems.splice(index, 1)
      }
    },
    isNDAProject(item) {
      return item?.featureFlags?.includes(ProjectFeatureFlag.NdaMc)
    },
  },
})
