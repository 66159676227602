
import MediaOverviewContent from "@/components/mediaHub/MediaOverviewContent"
import MediaOverviewHeader from "@/components/mediaHub/MediaOverviewHeader"
import MediaOverviewFooter from "@/components/mediaHub/MediaOverviewFooter"
import Vue, { PropType } from "vue"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import { useMediaHubStore } from "@/stores/mediaHub"

import { Media, MediaType } from "@evercam/shared/types/media"
import { useCameraStore } from "@/stores/camera"
import { Camera } from "@evercam/shared/types"
import CountDownFunction from "@/components/CountDownFunction.vue"

export default Vue.extend({
  name: "MediaOverview",
  components: {
    MediaOverviewContent,
    MediaOverviewHeader,
    MediaOverviewFooter,
    CountDownFunction,
  },
  props: {
    mediaItem: {
      type: Object as PropType<Media>,
      default: () => ({} as Media),
    },
  },
  data() {
    return {
      contentStyle: {},
    }
  },
  computed: {
    ...mapStores(
      useAccountStore,
      useProjectStore,
      useCameraStore,
      useMediaHubStore
    ),
    selectedCamera(): Camera {
      return this.cameraStore.cameras.find(
        (c) => c.id === this.mediaItem.cameraExid
      )
    },
    mediaProjectExid() {
      return this.$route.params.project_exid
    },
    mediaUrl(): string {
      const baseUrl = `${this.$config.public.apiURL}/projects/${this.mediaProjectExid}/media-hub`
      const mediaExid = this.mediaItem.exid
      const commonClipUrl = `${baseUrl}/${mediaExid}.mp4`
      const commonFileUrl = `${baseUrl}/${this.mediaItem.fileName}`

      const urlMap = {
        [MediaType.Clip]: commonClipUrl,
        [MediaType.LocalClip]: commonClipUrl,
        [MediaType.Compare]: commonClipUrl,
        [MediaType.ExternalUrl]: this.mediaItem?.mediaUrl || "",
        [MediaType.EditedImage]: commonFileUrl,
        [MediaType.XRay]: commonFileUrl,
        [MediaType.File]: commonFileUrl,
        [MediaType.Timelapse]: `${baseUrl}/${mediaExid}.${this.mediaItem.format}`,
        [MediaType.MobileAsset]: commonFileUrl,
      }

      const url = urlMap[this.mediaType]

      return this.accountStore.token && this.mediaType !== MediaType.ExternalUrl
        ? `${url}?authorization=${this.accountStore.token}`
        : url
    },
    canDelete(): boolean {
      const isMediaOwner =
        this.mediaItem.requesterEmail === this.accountStore.email
      const hasCameraEditRight =
        this.selectedCamera &&
        this.selectedCamera.rights.split(",").includes("edit")

      return isMediaOwner || hasCameraEditRight
    },
    mediaType(): MediaType {
      return this.mediaItem?.type
    },
    showMedia(): boolean {
      return (
        this.accountStore.token ||
        this.mediaItem?.public ||
        this.selectedCamera?.isPublic
      )
    },
  },
  mounted() {
    this.onResize()
  },
  methods: {
    getLinkPath() {
      return this.projectStore.selectedProject
        ? `${this.projectStore.projectRoute}/media-hub`
        : "/v2/projects"
    },
    onResize() {
      const appBarHeight =
        this.$globalRefs.appBar?.getBoundingClientRect()?.height || 0
      const header = this.accountStore.token
        ? this.$refs.mediaOverviewHeader?.$el?.getBoundingClientRect().bottom
        : appBarHeight
      const body =
        this.$refs.mediaOverviewContent?.$el?.children[0]?.getBoundingClientRect() ||
        {}
      const footer =
        this.$refs.mediaOverviewFooter?.$el?.getBoundingClientRect() || {}
      const bodyHeight = window.innerHeight - header - footer.height - 10
      if (this.$vuetify.breakpoint.smAndDown) {
        this.contentStyle = {
          height: `${bodyHeight}px`,
          width: "inherit",
        }

        return
      }
      this.contentStyle = {
        height: `${bodyHeight}px`,
        width: `${(body.width / body.height) * bodyHeight}px`,
        maxWidth: `${(body.width / body.height) * bodyHeight}px`,
      }
    },
  },
})
