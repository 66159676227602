
import MediaHubGalleryPlaceholder from "@/components/mediaHub/MediaHubGalleryPlaceholder"
import MediaHubGallery from "@/components/mediaHub/MediaHubGallery"
import Vue from "vue"
import { QueryParams, toQueryString, updateQuery } from "@evercam/shared/utils"
import { camelizeKeys } from "humps"
import {
  Media,
  MediaFilterQueryParams,
  MediaStatus,
  MediaType,
} from "@evercam/shared/types/media"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AnalyticsEvent, type PaginatedItems } from "@evercam/shared/types"
import { mapStores } from "pinia"
import { useMediaHubStore } from "@/stores/mediaHub"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import MediaHubHeaderFilters from "@/components/mediaHub/MediaHubHeaderFilters"
import DefaultPageWrapper from "@/components/DefaultPageWrapper"
import MediaHubPagination from "@/components/mediaHub/MediaHubPagination"
import MediaHubHeaderActions from "@/components/mediaHub/MediaHubHeaderActions"

export default Vue.extend({
  name: "MediaHubContainer",
  components: {
    MediaHubGalleryPlaceholder,
    MediaHubGallery,
    MediaHubHeaderFilters,
    MediaHubHeaderActions,
    DefaultPageWrapper,
    MediaHubPagination,
  },
  data() {
    const { selectedProject } = useProjectStore()

    return {
      loading: true,
      isRefreshingMediaHub: false,
      filters: {
        title: "" as string,
        requesterEmail: "" as string | undefined,
        camera: "" as string | undefined,
        type: "",
        fromDate: this.$moment(new Date(selectedProject?.startedAt)).format(
          "YYYY-MM-DD"
        ),
        toDate: this.$moment(new Date()).format("YYYY-MM-DD"),
        sortBy: "created_at|desc",
      },
      currentPage: 1,
    }
  },
  computed: {
    ...mapStores(useMediaHubStore, useProjectStore, useCameraStore),
    totalPages(): number {
      return Math.ceil(this.mediaHubStore.totalMediaItems / 12)
    },
  },
  created() {
    this.updateFilterFromUrl()
    this.onTimelapseCreationCompleted()
  },
  mounted() {
    this.currentPage = parseInt(this.$route.query.page as string) || 1
    this.getAllMedia(this.currentPage)
  },
  beforeDestroy() {
    this.mediaHubStore.$reset()
  },
  methods: {
    updateFilters(filters) {
      this.filters = { ...this.filters, ...filters }
      this.applyFilters()
    },
    applyFilters() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubApplyFilters)
      this.currentPage = 1
      this.getAllMedia()
    },
    refreshMediaHub() {
      this.isRefreshingMediaHub = true
      this.getAllMedia()
    },
    async getAllMedia(page = 1) {
      const format = "YYYY-MM-DD"
      try {
        this.loading = true

        const fromDate = this.$moment(this.filters.fromDate).format(format)
        const toDate = this.$moment(this.filters.toDate).format(format)
        const params: MediaFilterQueryParams = {
          title: this.filters.title,
          cameraExid: this.filters.camera as unknown as string,
          requesterEmail: this.filters.requesterEmail,
          type: this.filters.type as MediaType,
          dateRange: [fromDate, toDate],
          sort: this.filters.sortBy,
          page: page,
          limit: 12,
        }

        updateQuery(params as QueryParams)

        const processedParams: MediaFilterQueryParams = Object.entries(
          params
        ).reduce(
          (acc, [key, value]: [any, any]) =>
            !["", null, undefined].includes(value)
              ? { ...acc, [key]: value }
              : acc,
          {}
        )

        if (
          this.mediaHubStore.filterParamsStringify ===
            toQueryString(processedParams) &&
          !this.isRefreshingMediaHub
        ) {
          return
        }

        this.mediaHubStore.filterParamsStringify =
          toQueryString(processedParams)
        const response: PaginatedItems<Media> =
          await EvercamApi.mediaHub.getAllMedia(
            this.projectStore.selectedProjectExid,
            processedParams
          )
        this.mediaHubStore.totalMediaItems = response.total
        this.mediaHubStore.mediaItems = response.items
      } catch (e) {
        this.$errorTracker.save(e)
      } finally {
        this.loading = false
        this.isRefreshingMediaHub = false
      }
    },
    fetchPage(page: number) {
      if (!this.loading) {
        this.$analytics.saveEvent(AnalyticsEvent.MediaHubSelectPage)
        this.currentPage = page
        this.getAllMedia(page)
      }
    },
    updateFilterFromUrl() {
      const queryParams = camelizeKeys(
        this.$route.query
      ) as unknown as MediaFilterQueryParams

      this.filters = {
        ...this.filters,
        ...(queryParams.title && { title: queryParams.title }),
        ...(queryParams.sort && { sortBy: queryParams.sort }),
        ...(queryParams.dateRange?.[0] && {
          fromDate: queryParams.dateRange[0],
        }),
        ...(queryParams.dateRange?.[1] && { toDate: queryParams.dateRange[1] }),
        ...(queryParams.cameraExid && { camera: queryParams.cameraExid }),
        ...(queryParams.requesterEmail && {
          requesterEmail: queryParams.requesterEmail,
        }),
        ...(queryParams.type && { type: queryParams.type }),
      }
    },
    onTimelapseCreationCompleted() {
      this.$root.$on("timelapse-creation-completed", (mediaItem) => {
        if (mediaItem.status === "success") {
          this.mediaHubStore.mediaItems = this.mediaHubStore.mediaItems.map(
            (item: Media) => {
              if (item.exid === mediaItem.exid) {
                return {
                  ...item,
                  status: MediaStatus.Completed,
                  url: this.getThumbnailUrl(mediaItem),
                }
              }

              return item
            }
          )
          this.$notifications.success(
            this.$t("content.timelapse.creation_success")
          )
        } else {
          this.mediaHubStore.filterParamsStringify = null
          this.getAllMedia()
          this.$notifications.success(
            "Sorry, we could not process your request"
          )
        }
      })
    },
    getThumbnailUrl(mediaItem) {
      return `${this.$config.public.apiURL}/projects/${this.projectStore.selectedProjectExid}/media-hub/${mediaItem.exid}/thumbnail?type=${MediaType.Timelapse}`
    },
  },
})
