import { defineStore } from "pinia"
import moment from "moment-timezone"
import { DataOptions as VDataTableOptions } from "vuetify/types"
import {
  Camera,
  CameraExid,
  CameraFeatureFlag,
  Date_YYYY_MM_DD,
  EventCountResponsePayload,
  GateReportEventType,
  GateReportROI,
  GateReportVehicleType,
  GateReportVehicleTypeItem,
  SortingUrlParam,
  AnprDirection,
  GateReportDataSource,
  GateReportResponsePayload,
  GatReportRequestPayload,
  GateReportEventDirection,
  EventCountRequestPayload,
  AnprBasedGateReportExportRequestParams,
  ProjectFeatureFlag,
} from "@evercam/shared/types"
import {
  VEHICLE_TYPE_IDS,
  VEHICLE_TYPES,
} from "@evercam/shared/constants/gateReport"
import { AiApi } from "@evercam/shared/api/aiApi"
import axios from "@evercam/shared/api/client/axios"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { AxiosError } from "axios"
import { useNuxtApp } from "#app"

type GateReportState = {
  dailyStatus: { processed: Date_YYYY_MM_DD[] }
  eventCounts: EventCountResponsePayload
  report: GateReportResponsePayload
  isInitialLoad: boolean
  isDownloadingEvents: boolean
  isFetchingEventCounts: boolean
  isFetchingEvents: boolean
  options: VDataTableOptions
  rois: GateReportROI[]
  roisVisibility: {
    [roiId: number]: boolean
  }
  selectedCameras: CameraExid[]
  selectedDate: Date_YYYY_MM_DD
  selectedVehicleTypes: GateReportVehicleType[]
  selectedEventTypes: GateReportEventType[]
  showThumbnails: boolean
  sort: SortingUrlParam
  plateNumber: string
}

const emptyEventsResponse = {
  source: GateReportDataSource.Events,
  results: {
    from: 0,
    items: [],
    limit: 0,
    page: 0,
    to: 0,
    total: 0,
  },
}

export const useGateReportStore = defineStore({
  id: "gateReport",
  state: (): GateReportState => ({
    dailyStatus: { processed: [] },
    eventCounts: null,
    report: emptyEventsResponse,
    isInitialLoad: true,
    isDownloadingEvents: false,
    isFetchingEventCounts: false,
    isFetchingEvents: false,
    options: null,
    rois: [],
    roisVisibility: {},
    selectedCameras: [],
    selectedDate: null,
    selectedVehicleTypes: [],
    selectedEventTypes: [],
    showThumbnails: true,
    sort: "event_time|asc",
    plateNumber: null,
  }),
  actions: {
    async init() {
      this.isInitialLoad = true

      this.initSelectedCameras()
      this.initSelectedVehicleTypes()
      this.initSelectedEventTypes()
      this.fetchDailyStatus()

      await this.fetchEventCounts()

      if (!this.selectedDate) {
        this.initSelectedDate()
      }

      this.fetchEvents()
    },
    async initSelectedDate() {
      const days = this.eventCounts?.days || []
      const lastDayIndex = days?.length ? days?.length - 1 : 0
      this.selectedDate =
        days?.[lastDayIndex]?.day || moment().format("YYYY-MM-DD")
    },
    initSelectedCameras() {
      if (!this.selectedCameras.length) {
        this.selectedCameras = this.cameras.map((camera: Camera) => camera.id)
      }
    },

    initSelectedVehicleTypes() {
      if (!this.selectedVehicleTypes?.length) {
        this.selectedVehicleTypes = this.vehicleTypeIds
      }
    },

    initSelectedEventTypes() {
      if (!this.selectedEventTypes?.length) {
        this.selectedEventTypes = Object.values(GateReportEventType)
      }
    },
    async fetchEventCounts() {
      let isCancelled = false
      try {
        this.isFetchingEventCounts = true

        const cancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(cancelToken)

        let payload: EventCountRequestPayload = {
          camerasExid: this.selectedCameras,
          ...this.plateNumberFilter,
        }

        if (!this.isAnprLegacy) {
          payload = {
            ...payload,
            vehicleTypes: this.filteredVehicleTypes,
            isPublic: true,
          }
        }

        if (
          this.filteredVehicleTypes.length === 0 ||
          this.selectedCameras.length === 0
        ) {
          this.eventCounts = []
          this.isFetchingEventCounts = false

          return
        }

        this.eventCounts = await AiApi.gateReport.getCounts(
          useProjectStore().selectedProjectExid,
          payload,
          {
            cancelToken: cancelToken.token,
          }
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$errorTracker.save(error)
        isCancelled = axios.isCancel(error as AxiosError)
      } finally {
        if (!isCancelled) {
          this.isFetchingEventCounts = false
        }
      }
    },
    async fetchDailyStatus() {
      try {
        const projectExid = useProjectStore().selectedProjectExid
        const data = await AiApi.gateReport.getDailyStatus(projectExid, {
          camerasExid: this.selectedCameras,
        })
        this.dailyStatus = { processed: data || [] }
      } catch (error) {
        this.dailyStatus = { processed: [] }
        console.error(error)
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t("content.fetch_resource_failed", {
            resource: "gate report daily status",
          }) as string,
          error,
        })
      }
    },
    async fetchEvents() {
      let isCancelled = false
      try {
        this.isFetchingEvents = true
        const projectStore = useProjectStore()
        const timezone = projectStore.selectedProjectTimezone
        const projectExid = projectStore.selectedProjectExid
        let selectedDate = moment(this.selectedDate)
        if (!selectedDate.isValid()) {
          selectedDate = moment()
        }

        let eventTypeFilter
        if (!this.showAllEvents && this.showInEvents) {
          eventTypeFilter = { eventType: GateReportEventDirection.In }
        } else if (!this.showAllEvents && this.showOutEvents) {
          eventTypeFilter = { eventType: GateReportEventDirection.Out }
        }

        const fromDate = moment
          .tz(`${selectedDate.format("YYYY-MM-DD")}T00:00:00`, timezone)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss")
        const toDate = moment
          .tz(`${selectedDate.format("YYYY-MM-DD")}T23:59:59`, timezone)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss")
        let payload: GatReportRequestPayload = {
          fromDate,
          toDate,
          camerasExid: this.selectedCameras,
          page: this.options?.page || 1,
          sort: this.sort || "event_time|asc",
          ...eventTypeFilter,
          ...this.plateNumberFilter,
        }

        if (!this.isAnprLegacy) {
          payload = {
            ...payload,
            vehicleTypes: this.filteredVehicleTypes,
            isPublic: true,
          }
        }

        if (
          this.filteredVehicleTypes.length === 0 ||
          this.selectedCameras.length === 0
        ) {
          this.report = emptyEventsResponse
          this.isFetchingEvents = false

          return
        }

        // Add cancel tokens for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(cancelToken)

        this.report = await AiApi.gateReport.getEvents(projectExid, payload, {
          cancelToken: cancelToken.token,
        })
      } catch (error) {
        console.error(error)
        useNuxtApp().nuxt2Context.$errorTracker.save(error)
        isCancelled = axios.isCancel(error as AxiosError)
      } finally {
        if (!isCancelled) {
          this.isFetchingEvents = false
        }

        if (this.isInitialLoad) {
          this.isInitialLoad = false
        }
      }
    },

    resetEvents() {
      this.report = emptyEventsResponse
    },

    async fetchROIs() {
      try {
        const projectExid = useCameraStore().selectedCamera?.project?.id
        const ROIs = await AiApi.roi.getROIs(projectExid, {
          camerasExid: [useCameraStore().selectedCameraExid],
        })
        ROIs.forEach(
          (roi) =>
            (this.roisVisibility = {
              ...this.roisVisibility,
              [roi.id]: true,
            })
        )
        this.rois = ROIs
      } catch (e) {
        useNuxtApp().nuxt2Context.$errorTracker.save(e)
      }
    },

    async requestExport(params) {
      try {
        const projectStore = useProjectStore()
        const projectExid = projectStore.selectedProjectExid
        const timezone = projectStore.selectedProjectTimezone

        const formatDate = (date) =>
          moment.tz(date, timezone).utc().format("YYYY-MM-DD HH:mm:ss")

        let payload: AnprBasedGateReportExportRequestParams = {
          fromDate: formatDate(`${this.selectedDate} 00:00:00`),
          toDate: formatDate(`${this.selectedDate} 23:59:59`),
          camerasExid: this.selectedCameras,
          ...this.plateNumberFilter,
          isPublic: true,
          ...params,
        }

        if (this.isAnprLegacy) {
          return await this.exportAnprLegacyEventsProvider(projectExid, payload)
        }

        payload = {
          ...payload,
          vehicleTypes: this.filteredVehicleTypes,
        }

        if (this.showAnprBasedGateReport) {
          return await this.exportAnprBasedEventsProvider(projectExid, payload)
        }

        return await this.exportEventsProvider(projectExid, payload)
      } catch (error) {
        useNuxtApp().nuxt2Context.$errorTracker.save(error)
      }
    },
    exportAnprLegacyEventsProvider(projectExid, payload) {
      payload = {
        ...payload,
        ...this.anprDirectionFilter,
      }

      return AiApi.anpr.exportAnprLegacyEvents(projectExid, payload)
    },
    exportEventsProvider(projectExid, payload) {
      payload = {
        ...payload,
        ...this.eventTypeFilter,
      }

      return AiApi.gateReport.exportEvents(projectExid, payload)
    },
    exportAnprBasedEventsProvider(projectExid, payload) {
      payload = {
        ...payload,
        ...this.anprDirectionFilter,
      }

      return AiApi.anpr.exportAnprEvents(projectExid, payload)
    },
  },
  getters: {
    isAnprLegacy() {
      return useNuxtApp().nuxt2Context.$permissions.project.has.anprLegacy()
    },
    isLegacySelectedDate(): boolean {
      const selectedDay = this.eventCounts?.days?.find(
        (day) => day.day === this.selectedDate
      )

      return selectedDay?.source === GateReportDataSource.Events
    },
    hasAnprCameras(): boolean {
      return !!useProjectStore().selectedProjectCameras.find((camera: Camera) =>
        camera.featureFlags.includes(CameraFeatureFlag.ANPR)
      )
    },
    isAnprBasedProject(): boolean {
      return (
        this.hasAnprCameras &&
        useNuxtApp().nuxt2Context.$permissions.project.has.anprBasedGateReport()
      )
    },
    showAnprBasedGateReport(): boolean {
      return this.isAnprBasedProject && !this.isLegacySelectedDate
    },
    showLegacyGateReport(): boolean {
      return !this.isAnprBasedProject || this.isLegacySelectedDate
    },
    hidePlateNumberColumn(): boolean {
      return useProjectStore().selectedProject.featureFlags?.includes(
        ProjectFeatureFlag.NoLicensePlate
      )
    },
    getVehicleLabel: (state) => {
      return (id) =>
        state.vehicleTypes.find((type) => type.id === id)?.name || id
    },
    isEventInVisibleRois: (state) => {
      return (event) => {
        if (!event.roiId) {
          return Object.values(state.roisVisibility).reduce(
            (acc, roi) => acc || roi,
            false
          )
        }

        return state.roisVisibility[event.roiId]
      }
    },
    showInEvents: (state) => {
      return state.selectedEventTypes?.includes(GateReportEventType.Arrived)
    },
    showOutEvents: (state) => {
      return state.selectedEventTypes?.includes(GateReportEventType.Left)
    },
    showAllEvents: (state) => {
      return state.selectedEventTypes.length === 2
    },
    reportItems: (state) => {
      return state.report?.results?.items ?? []
    },
    reportSource: (state) => {
      return state.report?.source
    },
    reportEventsTotal: (state) => {
      return state.report?.results?.total || 0
    },
    plateNumberFilter(): { plateNumber?: string } {
      if (!this.isLegacySelectedDate && this.plateNumber) {
        return { plateNumber: this.plateNumber.replace(/-/g, "") }
      }

      return {}
    },
    eventTypeFilter() {
      if (!this.showAllEvents && this.showInEvents) {
        return { eventType: GateReportEventType.Arrived }
      } else if (!this.showAllEvents && this.showOutEvents) {
        return { eventType: GateReportEventType.Left }
      }

      return {}
    },
    anprDirectionFilter() {
      if (!this.showAllEvents && this.showInEvents) {
        return { direction: AnprDirection.Arrived }
      } else if (!this.showAllEvents && this.showOutEvents) {
        return { direction: AnprDirection.Left }
      }

      return {}
    },
    filteredVehicleTypes(): GateReportVehicleType[] {
      return this.selectedVehicleTypes?.filter(
        (id) =>
          this.showAnprBasedGateReport || id !== GateReportVehicleType.Unknown
      )
    },
    vehicleTypeIds(): GateReportVehicleType[] {
      return VEHICLE_TYPE_IDS.filter(
        (id) =>
          this.showAnprBasedGateReport || id !== GateReportVehicleType.Unknown
      )
    },
    vehicleTypes(): GateReportVehicleTypeItem[] {
      return VEHICLE_TYPES.filter(
        (t) =>
          this.showAnprBasedGateReport || t.id !== GateReportVehicleType.Unknown
      )
    },
    cameras() {
      return useProjectStore().selectedProjectCameras.filter((camera) =>
        this.isAnprLegacy
          ? camera.featureFlags.includes(CameraFeatureFlag.ANPR)
          : camera.featureFlags.includes(CameraFeatureFlag.GateReport) ||
            camera.featureFlags.includes(CameraFeatureFlag.ANPR)
      )
    },
  },
})
