
import Vue from "vue"
import { useMediaHubStore } from "@/stores/mediaHub"
import { mapStores } from "pinia"
import type { ESelectionGroupOption } from "@evercam/ui"
import { formField } from "@evercam/ui"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"

import { AnalyticsEvent, MediaType } from "@evercam/shared/types"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default Vue.extend({
  name: "MediaHubHeaderFilters",
  mixins: [formField],
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      isSearching: false,
      users: [] as ESelectionGroupOption[],
      types: [
        {
          label: this.$t("content.media_hub.media_type.all"),
          value: "",
        },
        {
          label: this.$t("content.media_hub.media_type.clip"),
          icon: "fas fa-cloud",
          value: MediaType.Clip,
        },
        {
          label: this.$t("content.media_hub.media_type.local_clip"),
          icon: "fas fa-hard-drive",
          value: MediaType.LocalClip,
        },
        {
          label: this.$t("content.media_hub.media_type.compare"),
          icon: this.$device.isIos ? "fas fa-compress" : "fas fa-left-right",
          value: MediaType.Compare,
        },
        {
          label: this.$t("content.media_hub.media_type.url"),
          icon: "fas fa-link",
          value: MediaType.ExternalUrl,
        },
        {
          label: this.$t("content.media_hub.media_type.file"),
          icon: "fas fa-file-arrow-up",
          value: MediaType.File,
        },
        {
          label: this.$t("content.media_hub.media_type.xray"),
          icon: "fas fa-x-ray",
          value: MediaType.XRay,
        },
        {
          label: this.$t("content.media_hub.media_type.mark_up"),
          icon: "fas fa-images",
          value: MediaType.EditedImage,
        },
        {
          label: this.$t("content.media_hub.media_type.timelapse"),
          icon: this.$device.isIos ? "fas fa-clock" : "fas fa-history",
          value: MediaType.Timelapse,
        },
        {
          label: this.$t("content.media_hub.media_type.mobile"),
          icon: "fas fa-mobile-screen-button",
          value: MediaType.MobileAsset,
        },
      ],
      localFilters: {},
    }
  },
  computed: {
    ...mapStores(useMediaHubStore, useCameraStore, useProjectStore),
    projectCameras(): ESelectionGroupOption[] {
      return this.cameraStore.cameras
        ?.filter(
          (camera) =>
            camera.project.id === this.projectStore.selectedProjectExid
        )
        .map((item) => ({
          label: item.name,
          value: item.id,
        }))
    },
    formattedStartDate(): string {
      let date
      if (this.localFilters.fromDate) {
        date = this.$moment(this.localFilters.fromDate).format("DD/MM/YYYY")
      } else {
        date = this.$moment().subtract({ months: 2 }).format("DD/MM/YYYY")
      }

      return date
    },
    formattedEndDate(): string {
      return this.$moment(this.localFilters.toDate || new Date()).format(
        "DD/MM/YYYY"
      )
    },
  },
  watch: {
    filters: {
      handler(filters) {
        this.localFilters = { ...filters }
      },
      deep: true,
      immediate: true,
    },
    localFilters: {
      handler(newFilters) {
        if (JSON.stringify(newFilters) !== JSON.stringify(this.filters)) {
          this.updateFilters()
        }
      },
      deep: true,
    },
    "localFilters.camera"(value, oldValue) {
      if (value === oldValue) {
        return
      }
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubSelectFilter, {
        type: "camera",
        value,
      })
    },
    "localFilters.type"(v) {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubSelectFilter, {
        type: "mediaType",
        value: v,
      })
    },
    "localFilters.requesterEmail"() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubSelectFilter, {
        type: "requesterEmail",
      })
    },
    "localFilters.fromDate"() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubSelectFilter, {
        type: "fromDate",
      })
    },
    "localFilters.toDate"() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubSelectFilter, {
        type: "toDate",
      })
    },
  },
  mounted() {
    this.fetchMediaHubUsersForProjects()
  },
  methods: {
    async fetchMediaHubUsersForProjects() {
      try {
        const users = await EvercamApi.mediaHub.getAllMediaUsers(
          this.projectStore.selectedProjectExid
        )

        this.users = users
          .filter((user) => user.name !== "User Deleted")
          .map((user) => ({
            label: user.name,
            value: user.email,
          }))
      } catch (error) {
        this.users = []
        this.$notifications.error({
          text: this.$t("content.media_hub.failed_to_fetch_users").toString(),
          error,
        })
      }
    },
    updateFilters() {
      this.$emit("update-filters", this.localFilters)
    },
  },
})
