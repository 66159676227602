
import Vue, { PropType } from "vue"
import { EZoomable } from "@evercam/ui"
import { AdminCamera, Camera, CameraExid } from "@evercam/shared/types/camera"
import axios from "@evercam/shared/api/client/axios"

export default Vue.extend({
  name: "JpegPlayer",
  components: {
    EZoomable,
  },
  props: {
    camera: {
      type: Object as PropType<Camera | AdminCamera>,
      required: true,
    },
    refreshRate: {
      type: Number,
      default: 50_000,
    },
    delay: {
      type: Number,
      default: 0,
    },
    isZoomable: {
      type: Boolean,
      default: true,
    },
    isPlaying: {
      type: Boolean,
      default: true,
    },
    token: {
      type: String,
      default: "",
    },
    resize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      src: null,
      lastRenderedSrc: "",
    }
  },
  computed: {
    cameraExid(): CameraExid {
      return (this.camera as AdminCamera).exid || (this.camera as Camera).id
    },
    cameraUrl(): string {
      return `${axios.env.baseUrl}/cameras/${this.cameraExid}`
    },
  },
  watch: {
    isPlaying(v) {
      if (v) {
        this.refresh()
      }
    },
    resize(v) {
      this.src = this.getLatestSnapshotSrc(v)
    },
  },
  created() {
    this.$setTimeout(this.refresh, this.delay)
  },
  methods: {
    refresh() {
      this.src = this.getLatestSnapshotSrc(this.resize)
    },
    getQueryParams(): string {
      return `authorization=${this.token}&view=true&cacheBuster=${Date.now()}`
    },
    getLatestSnapshotSrc(resize = true) {
      const snapshotSrc = `${
        this.cameraUrl
      }/recordings/snapshots/latest?${this.getQueryParams()}`

      return resize
        ? this.$imgproxy.get720pResizedImageUrl(snapshotSrc)
        : snapshotSrc
    },
    getThumbnailSrc(): string {
      return `${this.cameraUrl}/thumbnail?${this.getQueryParams()}}`
    },
    onImageLoaded() {
      this.lastRenderedSrc = this.src
      this.$emit("snapshot-loaded")
      if (this.isPlaying) {
        this.$setTimeout(this.refresh, this.refreshRate)
      }
    },
    onImageError() {
      if (this.resize) {
        this.src = this.getLatestSnapshotSrc(false)
      } else if (this.lastRenderedSrc && this.src !== this.lastRenderedSrc) {
        this.src = this.lastRenderedSrc
      } else {
        this.src = this.getThumbnailSrc()
      }
    },
  },
})
