
import Vue from "vue"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import PtzController from "@/components/ptz/PtzController"
import PtzPresetsList from "@/components/ptz/PtzPresetsList"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import RightSidebarContent from "@/components/portals/RightSidebarContent"
import { PtzApi } from "@evercam/shared/api/ptzApi"
import { useLayoutStore } from "@/stores/layout"

export default Vue.extend({
  name: "PtzBox",
  components: {
    PtzController,
    PtzPresetsList,
    RightSidebarContent,
  },
  props: {
    showSidebarOnMount: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: () => true,
    },
    camera: {
      type: [Object, undefined],
      default: () => undefined,
    },
  },
  computed: {
    ...mapStores(useAccountStore, useLayoutStore),
    disablePtz(): boolean {
      return this.camera?.rights
        ?.split(",")
        .some((o) => ["edit", "share"].includes(o))
    },
    doDisablePtz() {
      return this.disablePtz ? this.isPlaying : this.disablePtz
    },
  },
  watch: {
    "layoutStore.isRightSidebarVisible"() {
      this.$analytics.saveEvent(AnalyticsEvent.PTZTogglePresetsMenu, {
        isRecordingFromNvr: this.camera.recordingFromNvr,
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.showSidebarOnMount) {
        this.layoutStore.enableRightSidebar()
      }
    })
  },
  methods: {
    async move(direction) {
      let ptzMoveEvent = ""
      switch (direction) {
        case "up":
          ptzMoveEvent = AnalyticsEvent.PTZMoveUp
          break
        case "down":
          ptzMoveEvent = AnalyticsEvent.PTZMoveDown
          break
        case "left":
          ptzMoveEvent = AnalyticsEvent.PTZMoveLeft
          break
        case "right":
          ptzMoveEvent = AnalyticsEvent.PTZMoveRight
          break
      }
      this.$analytics.saveEvent(ptzMoveEvent, {
        direction,
      })
      try {
        await PtzApi.controls.move({
          cameraExid: this.camera.id,
          direction,
          isRecordingFromNvr: this.camera.recordingFromNvr,
        })
        this.$setTimeout(this.stopMove)
      } catch (error) {
        this.$notifications.error({ error })
      }
    },
    async selectHomePreset() {
      this.$analytics.saveEvent(AnalyticsEvent.PTZResetPosition, {
        isRecordingFromNvr: this.camera.recordingFromNvr,
      })
      try {
        await PtzApi.presets.home({
          cameraExid: this.camera.id,
          isRecordingFromNvr: this.camera.recordingFromNvr,
        })
      } catch (error) {
        this.$notifications.error({ error })
      }
    },
    async zoom(direction) {
      const ptzZoomEvent =
        direction === "in"
          ? AnalyticsEvent.PTZZoomIn
          : AnalyticsEvent.PTZZoomOut
      this.$analytics.saveEvent(ptzZoomEvent, {
        direction,
      })
      try {
        await PtzApi.controls.zoom({
          cameraExid: this.camera.id,
          direction,
          isRecordingFromNvr: this.camera.recordingFromNvr,
        })
        this.$setTimeout(this.stopMove)
      } catch (error) {
        this.$notifications.error({ error })
      }
    },
    async stopMove() {
      try {
        await PtzApi.controls.stop({
          cameraExid: this.camera.id,
        })
      } catch (error) {
        this.$notifications.error({ error })
      }
    },
  },
})
