import { defineStore } from "pinia"
import moment from "moment-timezone"
import { schedules, subtractOneHourFromEndIntervals } from "@evercam/ui"
import {
  TimelapseDuration,
  TimelapseExportStep,
  TimelapsePeriod,
  TimelapseSchedules,
} from "@evercam/shared/types/timelapse"
import { ScheduleType, Schedule } from "@evercam/ui"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useNuxtApp } from "#app"
import axios from "@evercam/shared/api/client/axios"

enum TimelapseUrlParam {
  ScheduleType = "scheduleType",
  SelectedPeriod = "selectedPeriod",
  FromDatetime = "fromDatetime",
  ToDatetime = "toDatetime",
}

type TimelapseState = {
  scheduleType: ScheduleType
  selectedDuration: TimelapseDuration
  selectedPeriod: TimelapsePeriod
  schedule: TimelapseSchedules
  fromDatetime: string
  toDatetime: string
  resolution: any
  removeTimestamp: boolean
  smoothTransition: boolean
  saveDialog: boolean
  title: string
  stepper: TimelapseExportStep
  isFetchingSnapshots: boolean
  snapshots: any[]
}

export const useTimelapseStore = defineStore("timelapse", {
  state: (): TimelapseState => ({
    scheduleType: ScheduleType.Continuous,
    selectedDuration: TimelapseDuration.SixtySeconds,
    selectedPeriod: TimelapsePeriod.Day,
    schedule: schedules.continuous,
    fromDatetime: moment
      .utc(new Date())
      .subtract({ days: Number(TimelapsePeriod.Month) })
      .format(),
    toDatetime: moment.utc().format(),
    resolution: {},
    removeTimestamp: false,
    smoothTransition: false,
    saveDialog: false,
    title: "",
    stepper: TimelapseExportStep.Format,
    isFetchingSnapshots: false,
    snapshots: [],
  }),
  actions: {
    partialUnsetProperties() {
      this.saveDialog = false
      this.title = ""
      this.stepper = TimelapseExportStep.Format
    },
    unsetProperties() {
      this.selectedDuration = TimelapseDuration.SixtySeconds
      this.schedule = schedules.continuous
      this.resolution = {}
      this.removeTimestamp = false
      this.smoothTransition = false
      this.saveDialog = false
      this.title = ""
      this.stepper = TimelapseExportStep.Format
    },
    async fetchSnapshots(cameraId: string) {
      try {
        axios.cancelRequests()
        this.isFetchingSnapshots = true
        this.snapshots = []

        // Add cancel token for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(cancelToken)

        const schedule = subtractOneHourFromEndIntervals(
          this.schedule
        ) as Schedule

        const snapshots = await EvercamApi.timelapse.getSnapshotRange(
          cameraId,
          {
            from: this.fromDatetime,
            to: this.toDatetime,
            schedule: JSON.stringify(schedule),
            count: 120,
          },
          { cancelToken: cancelToken?.token }
        )
        this.snapshots = snapshots.snapshots
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t("content.fetch_resource_failed", {
            resource: "snapshots",
          }),
          error,
        })
        this.snapshots = null
      } finally {
        this.isFetchingSnapshots = false
      }
    },
  },
  syncWithUrl: Object.values(TimelapseUrlParam) as (keyof TimelapseState)[],
})
