
export default {
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      settings: {
        timeline: false,
        darkMode: false,
      },
    }
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        this.$emit("settings-changed", value)
      },
    },
  },
  mounted() {
    for (const key of Object.keys(this.widget.settings)) {
      if (this.widget.settings[key] !== undefined) {
        this.settings[key] = this.widget.settings[key]
      }
    }
  },
}
