import { AnalyticsRequestPayload } from "@evercam/shared/types/analytics"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useAccountStore } from "@/stores/account"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import { defineNuxtPlugin, useNuxtApp } from "#app"
export default defineNuxtPlugin((app) => {
  const {
    $errorTracker,
    $device,
    isDev,
    $config,
    $permissions,
    $posthog,
    $auth,
    env,
    query,
  } = app.nuxt2Context

  const isDisabled = () => {
    const accountStore = useAccountStore()

    const isInternalUser =
      ($permissions.user.is.admin() ||
        accountStore.isImpersonationLogin ||
        accountStore.isApiLogin) &&
      !$permissions.user.is.paAdmin()

    const isOfflineUser =
      !accountStore.email || !accountStore.token || $auth.isTokenExpired()

    const isDevEnvironment = isDev || env.NUXT_ENV_VERCEL_ENV === "preview"

    return (
      !$config?.public.analyticsEnabled ||
      isDevEnvironment ||
      isInternalUser ||
      isOfflineUser
    )
  }
  const isVerboseMode = () => {
    const { isAdmin, isImpersonationLogin } = useAccountStore()

    return (
      (query?.verboseAnalytics === "true" ||
        query?.verbose_analytics === "true") &&
      (isAdmin || isImpersonationLogin)
    )
  }

  const analytics = {
    saveEvent: (eventId, extraParams = {}) => {
      const accountStore = useAccountStore()
      const cameraStore = useCameraStore()
      const projectStore = useProjectStore()

      const params: AnalyticsRequestPayload = {
        name: eventId,
        cameraExid: cameraStore.selectedCameraExid,
        ...extraParams,
      }

      const pageId = useNuxtApp()?.nuxt2Context?.route?.meta.find(
        (item) => !!item?.pageId
      )?.pageId

      if (!pageId) {
        console.error(
          `Missing page ID for page: ${
            useNuxtApp()?.nuxt2Context?.route?.name
          },  event "${eventId}"!`
        )

        return new Promise((resolve) => resolve({}))
      }

      let finalEventId = `${pageId}-${eventId}` // All event will have the same format {pageId-context-action}, context-action is currently set as eventId

      const finalParams = {
        ...extraParams,
        pageId,
        cameraExid: cameraStore.selectedCameraExid,
        cameraName: cameraStore.selectedCamera?.name,
        projectExid: projectStore.selectedProjectExid,
        projectName: projectStore.selectedProjectName,
        companyExid: accountStore.companyExid,
        companyName: accountStore.companyName,
      }

      // Enable posthog verbose analytics mode using a special query param for admins & impersonation users
      if (isVerboseMode()) {
        console.log(`> Posthog captured event : ${finalEventId}`)
        console.log("Captured event properties:", finalParams)
      }

      if (isDisabled()) {
        return new Promise((resolve) => resolve({}))
      }

      $posthog.capture(finalEventId, finalParams)

      return EvercamApi.analytics
        .sendAnalyticsEvent(params, {
          headers: {
            "x-client-type": $device.context,
          },
        })
        .catch((error) => {
          if (error instanceof Error || typeof error === "string") {
            $errorTracker?.save(error)
          }
        })
    },
  }

  app.provide("analytics", analytics)

  app.hook("app:mounted", () => {
    app.vue2App.$root.$on("analytics-event", ({ eventId, params = {} }) => {
      analytics.saveEvent(eventId, params)
    })
  })
})
