
import uaparser from "ua-parser-js"
import { debounce } from "@evercam/shared/utils"
import CtkDateTimePicker from "@evercam/shared/components/CtkDateTimePicker"
import LOG_TYPES from "@evercam/shared/constants/logTypes"
import ErrorsCauseDescriptions from "@evercam/shared/constants/errorCause"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"

export default {
  name: "Activities",
  meta: {
    pageId: AnalyticsEventPageId.Activities,
  },
  components: {
    CtkDateTimePicker,
  },
  async asyncData({ $moment, app }) {
    let fromDatetime = `${$moment()
      .subtract(60, "days")
      .format("YYYY-MM-DDTHH:mm:ss")}`
    let toDatetime = `${$moment().format("YYYY-MM-DDTHH:mm:ss")}`
    let payload = {
      limit: 10000,
      sort: "inserted_at|desc",
      from: `${fromDatetime}.000Z`,
      to: `${toDatetime}.000Z`,
    }
    let logs = []
    try {
      const userLogs = await EvercamApi.users.getSessionActivities(payload)
      logs = userLogs.items
    } catch (error) {
      app.$notifications.error({
        text: app.i18n.t("content.activities.logs_load_error"),
        error,
      })
    }

    return {
      activities: logs,
      fromDatetime,
      toDatetime,
    }
  },
  data() {
    return {
      parser: new uaparser(),
      loading: false,
      headers: [
        {
          text: "Browser",
          value: "browser",
          class: "caption font-weight-medium",
        },
        {
          text: "IP address",
          value: "ip",
          class: "caption font-weight-medium",
        },
        {
          text: "Country",
          value: "country",
          class: "caption font-weight-medium",
        },
        {
          text: "Date & Time",
          value: "insertedAt",
          class: "caption font-weight-medium",
        },
        {
          text: "Event",
          value: "event",
          class: "caption font-weight-medium",
        },
      ],
      logTypes: LOG_TYPES,
      causeDescriptions: ErrorsCauseDescriptions,
    }
  },
  head() {
    return {
      title: "Evercam | User Activities",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    hideFooter() {
      return this.activities?.length <= 15
    },
  },
  watch: {
    fromDatetime: "debouncedFilterLog",
    toDatetime: "debouncedFilterLog",
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
  methods: {
    getOfflineCause(log) {
      return this.causeDescriptions[log?.details?.reason] || ""
    },
    getNoteTypeStyle(action) {
      return action === this.logTypes.CUSTOM
        ? "background-color: #FFFF8D; color: #1c1c1c;"
        : "background-color: #C8E6C9; color: #1c1c1c;"
    },
    getEvent(log) {
      let log_types = [
        {
          types: [this.logTypes.VH_STATUS],
          text: () => "Virtual Host auto-enabled by system",
        },
        {
          types: [this.logTypes.SHARE_REQUESTS],
          text: () =>
            `Sent camera (${log.details.name}) share request to ${log.details.with}`,
        },
        {
          types: [this.logTypes.DELETE_SHARE_REQUESTS],
          text: () =>
            `Deleted camera (${log.details.name}) share request for ${log.details.with}`,
        },
        {
          types: [this.logTypes.SHARE],
          text: () =>
            `Camera (${log.details.name}) shared with ${log.details.with}`,
        },
        {
          types: [this.logTypes.DELETE_SHARE],
          text: () =>
            `Stopped camera (${log.details.name}) sharing with ${log.details.with}`,
        },
        {
          types: [this.logTypes.UPDATE_SHARE],
          text: () =>
            `Updated camera (${log.details.name}) share rights for ${log.details.with}`,
        },
        {
          types: [this.logTypes.CR_CREATED],
          text: () => `Cloud recordings created for camera ${log.details.name}`,
        },
        {
          types: [this.logTypes.CR_UPDATED],
          text: () => `Coud recordings updated for camera ${log.details.name}`,
        },
        {
          types: [this.logTypes.LINK_SIM_WITH_ROUTER],
          text: () =>
            `Linked SIM ${log.details.router.simNumber} with router ${log.details.router.serialNumber}`,
        },
        {
          types: [this.logTypes.UNLINK_SIM_FROM_ROUTER],
          text: () =>
            `Unlinked SIM ${log.details.router.simNumber} from router ${log.details.router.serialNumber}`,
        },
        {
          types: [this.logTypes.LINK_CAMERA_WITH_ROUTER],
          text: () =>
            `Linked camera ${log.details.router.cameraName} with router ${log.details.router.serialNumber}`,
        },
        {
          types: [this.logTypes.UNLINK_CAMERA_FROM_ROUTER],
          text: () =>
            `Unlinked camera ${log.details.router.cameraName} from router ${log.details.router.serialNumber}`,
        },
        {
          types: [this.logTypes.DEVICE_SETTINGS],
          text: () => log.details.message,
        },
        {
          types: [
            this.logTypes.CREATE,
            this.logTypes.UPDATE,
            this.logTypes.DELETE,
          ],
          text: () => {
            if (log.entity === "cameras") {
              return `Camera (${log.details.name}) ${log.action}d`
            } else if (log.entity === "routers") {
              return `Router (${log.details?.router?.serialNumber}) ${log.action}d`
            } else if (log.entity === "archives") {
              let type = log.details.type
              let archive_text = type.charAt(0).toUpperCase() + type.slice(1)

              return `${archive_text} ${log.action}d for camera ${log.details.camera.name}`
            } else {
              return `Sim (${log.details?.sim?.number}) ${log.action}d`
            }
          },
        },
      ]

      let log_text = log.action
      log_types.forEach((item) => {
        if (item.types.includes(log.action)) {
          log_text = item.text()
        }
      })

      return log_text
    },
    showFlag(item) {
      return item?.userDetails?.country && item?.userDetails?.countryCode
    },
    humanizeDate(date) {
      return this.$moment(date).format("llll")
    },
    updateWaitingStatus(status) {
      return status && status.replace("waiting", "waiting for activation")
    },
    onChangeToTime(value) {
      this.toDatetime = this.$moment(value).format("YYYY-MM-DDTHH:mm:ss")
      this.$analytics.saveEvent(AnalyticsEvent.ActivitiesSettingsToDateSelect, {
        toDatetime: this.fromDatetime,
      })
    },
    onChangeFromTime(value) {
      this.fromDatetime = this.$moment(value).format("YYYY-MM-DDTHH:mm:ss")
      this.$analytics.saveEvent(
        AnalyticsEvent.ActivitiesSettingsFromDateSelect,
        {
          fromDatetime: this.fromDatetime,
        }
      )
    },
    getBrowserInfo(item) {
      let browserInfo = {
        icon: "",
        text: item.userDetails.browser,
      }

      if (item.userDetails.os === "iOS") {
        browserInfo.icon = "fab fa-apple"
        browserInfo.text = "iOS APP"
      } else if (item.userDetails.os === "Android") {
        browserInfo.icon = "fab fa-android"
        browserInfo.text = "Andriod APP"
      } else if (item.userDetails.browser.indexOf("Safari") !== -1) {
        browserInfo.icon = "fab fa-safari"
      } else if (item.userDetails.browser.indexOf("Edge") !== -1) {
        browserInfo.icon = "fab fa-edge"
      } else if (item.userDetails.browser.indexOf("Firefox") !== -1) {
        browserInfo.icon = "fab fa-firefox"
      } else if (item.userDetails.browser.indexOf("Chrome") !== -1) {
        browserInfo.icon = "fab fa-chrome"
      } else if (
        [
          this.logTypes.ON_HOLD,
          this.logTypes.WAITING,
          this.logTypes.DECOMMISSIONED,
          this.logTypes.ONLINE,
          this.logTypes.UNDER_MAINTENANCE,
          this.logTypes.WAITING_FOR_SITE_VISIT,
        ].includes(item.action)
      ) {
        browserInfo.icon = "fab fa-chrome"
        browserInfo.text = "Chrome on Windows(Admin)"
      }

      return browserInfo
    },
    debouncedFilterLog: debounce(function () {
      this.filterLog()
    }),
    async filterLog() {
      this.loading = true
      let payload = {
        limit: 10000,
        from: `${this.fromDatetime}.000Z`,
        to: `${this.toDatetime}.000Z`,
      }
      try {
        const userLogs = await EvercamApi.users.getSessionActivities(payload)
        this.activities = userLogs.items
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.activities.logs_load_error"),
          error,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
