
import { VideoWallItemType } from "@evercam/shared/types/videoWall"
import VideoWallGallery from "@/components/videoWalls/VideoWallGallery"
import { useVideoWallStore } from "@/stores/videoWall"
import { mapStores } from "pinia"
import Vue from "vue"
import { useCameraStore } from "@/stores/camera"

export default Vue.extend({
  name: "UserVideoWall",
  components: { VideoWallGallery },
  async asyncData({ route }) {
    const { hideSettings } = route.query

    return { hideSettings: hideSettings === "true" }
  },
  async fetch() {
    const videoWallStore = useVideoWallStore()
    await videoWallStore.fetchVideoWallConfiguration(true)
  },
  head() {
    return {
      title: "Evercam | Video Wall",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useVideoWallStore, useCameraStore),
    items() {
      return this.cameraStore.cameras.map((c) => ({
        value: c,
        type: VideoWallItemType.LiveView,
      }))
    },
  },
})
