export enum CountryId {
  Andorra = 1,
  UnitedArabEmirates = 2,
  Afghanistan = 3,
  AntiguaAndBarbuda = 4,
  Anguilla = 5,
  Albania = 6,
  Armenia = 7,
  NetherlandsAntilles = 8,
  Angola = 9,
  Antarctica = 10,
  Argentina = 11,
  AmericanSamoa = 12,
  Austria = 13,
  Australia = 14,
  Aruba = 15,
  AlandIslands = 16,
  Azerbaijan = 17,
  BosniaAndHerzegovina = 18,
  Barbados = 19,
  Bangladesh = 20,
  Belgium = 21,
  BurkinaFaso = 22,
  Bulgaria = 23,
  Bahrain = 24,
  Burundi = 25,
  Benin = 26,
  Bermuda = 27,
  BruneiDarussalam = 28,
  Bolivia = 29,
  Brazil = 30,
  Bahamas = 31,
  Bhutan = 32,
  BouvetIsland = 33,
  Botswana = 34,
  Belarus = 35,
  Belize = 36,
  Canada = 37,
  CocosKeelingIslands = 38,
  CongoKinshasa = 39,
  CentralAfricanRepublic = 40,
  CongoBrazzaville = 41,
  Switzerland = 42,
  CoteDIvoire = 43,
  CookIslands = 44,
  Chile = 45,
  Cameroon = 46,
  China = 47,
  Colombia = 48,
  CostaRica = 49,
  Cuba = 50,
  CapeVerde = 51,
  ChristmasIsland = 52,
  Cyprus = 53,
  CzechRepublic = 54,
  Germany = 55,
  Djibouti = 56,
  Denmark = 57,
  Dominica = 58,
  DominicanRepublic = 59,
  Algeria = 60,
  Ecuador = 61,
  Estonia = 62,
  Egypt = 63,
  WesternSahara = 64,
  Eritrea = 65,
  Spain = 66,
  Ethiopia = 67,
  Finland = 68,
  Fiji = 69,
  FalklandIslands = 70,
  Micronesia = 71,
  FaroeIslands = 72,
  France = 73,
  Gabon = 74,
  UnitedKingdom = 75,
  Grenada = 76,
  Georgia = 77,
  FrenchGuiana = 78,
  Guernsey = 79,
  Ghana = 80,
  Gibraltar = 81,
  Greenland = 82,
  Gambia = 83,
  Guinea = 84,
  Guadeloupe = 85,
  EquatorialGuinea = 86,
  Greece = 87,
  SouthGeorgiaAndTheSouthSandwichIslands = 88,
  Guatemala = 89,
  Guam = 90,
  GuineaBissau = 91,
  Guyana = 92,
  HongKong = 93,
  HeardIslandAndMcDonaldIslands = 94,
  Honduras = 95,
  Croatia = 96,
  Haiti = 97,
  Hungary = 98,
  Indonesia = 99,
  Ireland = 100,
  Israel = 101,
  IsleOfMan = 102,
  India = 103,
  BritishIndianOceanTerritory = 104,
  Iraq = 105,
  Iran = 106,
  Iceland = 107,
  Italy = 108,
  Jersey = 109,
  Jamaica = 110,
  Jordan = 111,
  Japan = 112,
  Kenya = 113,
  Kyrgyzstan = 114,
  Cambodia = 115,
  Kiribati = 116,
  Comoros = 117,
  SaintKittsAndNevis = 118,
  NorthKorea = 119,
  SouthKorea = 120,
  Kuwait = 121,
  CaymanIslands = 122,
  Kazakhstan = 123,
  Laos = 124,
  Lebanon = 125,
  SaintLucia = 126,
  Liechtenstein = 127,
  SriLanka = 128,
  Liberia = 129,
  Lesotho = 130,
  Lithuania = 131,
  Luxembourg = 132,
  Latvia = 133,
  LibyanArabJamahiriya = 134,
  Morocco = 135,
  Monaco = 136,
  Moldova = 137,
  Montenegro = 138,
  Madagascar = 139,
  MarshallIslands = 140,
  Macedonia = 141,
  Mali = 142,
  Myanmar = 143,
  Mongolia = 144,
  Macao = 145,
  NorthernMarianaIslands = 146,
  Martinique = 147,
  Mauritania = 148,
  Montserrat = 149,
  Malta = 150,
  Mauritius = 151,
  Maldives = 152,
  Malawi = 153,
  Mexico = 154,
  Malaysia = 155,
  Mozambique = 156,
  Namibia = 157,
  NewCaledonia = 158,
  Niger = 159,
  NorfolkIsland = 160,
  Nigeria = 161,
  Nicaragua = 162,
  Netherlands = 163,
  Norway = 164,
  Nepal = 165,
  Nauru = 166,
  Niue = 167,
  NewZealand = 168,
  Oman = 169,
  Panama = 170,
  Peru = 171,
  FrenchPolynesia = 172,
  PapuaNewGuinea = 173,
  Philippines = 174,
  Pakistan = 175,
  Poland = 176,
  SaintPierreAndMiquelon = 177,
  Pitcairn = 178,
  PuertoRico = 179,
  PalestinianTerritory = 180,
  Portugal = 181,
  Palau = 182,
  Paraguay = 183,
  Qatar = 184,
  Reunion = 185,
  Romania = 186,
  Serbia = 187,
  RussianFederation = 188,
  Rwanda = 189,
  SaudiArabia = 190,
  SolomonIslands = 191,
  Seychelles = 192,
  Sudan = 193,
  Sweden = 194,
  Singapore = 195,
  SaintHelena = 196,
  Slovenia = 197,
  SvalbardAndJanMayen = 198,
  Slovakia = 199,
  SierraLeone = 200,
  SanMarino = 201,
  Senegal = 202,
  Somalia = 203,
  Suriname = 204,
  SaoTomeAndPrincipe = 205,
  ElSalvador = 206,
  SyrianArabRepublic = 207,
  Swaziland = 208,
  TurksAndCaicosIslands = 209,
  Chad = 210,
  FrenchSouthernTerritories = 211,
  Togo = 212,
  Thailand = 213,
  Tajikistan = 214,
  Tokelau = 215,
  TimorLeste = 216,
  Turkmenistan = 217,
  Tunisia = 218,
  Tonga = 219,
  Turkey = 220,
  TrinidadAndTobago = 221,
  Tuvalu = 222,
  Taiwan = 223,
  Tanzania = 224,
  Ukraine = 225,
  Uganda = 226,
  UnitedStatesMinorOutlyingIslands = 227,
  UnitedStates = 228,
  Uruguay = 229,
  Uzbekistan = 230,
  VaticanCityState = 231,
  SaintVincentAndTheGrenadines = 232,
  Venezuela = 233,
  VirginIslandsBritish = 234,
  VirginIslandsUS = 235,
  VietNam = 236,
  Vanuatu = 237,
  WallisAndFutuna = 238,
  Samoa = 239,
  Yemen = 240,
  Mayotte = 241,
  SouthAfrica = 242,
  Zambia = 243,
  Zimbabwe = 244,
}

export enum CountryCode {
  Andorra = "ad",
  UnitedArabEmirates = "ae",
  Afghanistan = "af",
  AntiguaAndBarbuda = "ag",
  Anguilla = "ai",
  Albania = "al",
  Armenia = "am",
  NetherlandsAntilles = "an",
  Angola = "ao",
  Antarctica = "aq",
  Argentina = "ar",
  AmericanSamoa = "as",
  Austria = "at",
  Australia = "au",
  Aruba = "aw",
  AlandIslands = "ax",
  Azerbaijan = "az",
  BosniaAndHerzegovina = "ba",
  Barbados = "bb",
  Bangladesh = "bd",
  Belgium = "be",
  BurkinaFaso = "bf",
  Bulgaria = "bg",
  Bahrain = "bh",
  Burundi = "bi",
  Benin = "bj",
  Bermuda = "bm",
  BruneiDarussalam = "bn",
  Bolivia = "bo",
  Brazil = "br",
  Bahamas = "bs",
  Bhutan = "bt",
  BouvetIsland = "bv",
  Botswana = "bw",
  Belarus = "by",
  Belize = "bz",
  Canada = "ca",
  CocosKeelingIslands = "cc",
  CongoKinshasa = "cd",
  CentralAfricanRepublic = "cf",
  CongoBrazzaville = "cg",
  Switzerland = "ch",
  CoteDIvoire = "ci",
  CookIslands = "ck",
  Chile = "cl",
  Cameroon = "cm",
  China = "cn",
  Colombia = "co",
  CostaRica = "cr",
  Cuba = "cu",
  CapeVerde = "cv",
  ChristmasIsland = "cx",
  Cyprus = "cy",
  CzechRepublic = "cz",
  Germany = "de",
  Djibouti = "dj",
  Denmark = "dk",
  Dominica = "dm",
  DominicanRepublic = "do",
  Algeria = "dz",
  Ecuador = "ec",
  Estonia = "ee",
  Egypt = "eg",
  WesternSahara = "eh",
  Eritrea = "er",
  Spain = "es",
  Ethiopia = "et",
  Finland = "fi",
  Fiji = "fj",
  FalklandIslands = "fk",
  Micronesia = "fm",
  FaroeIslands = "fo",
  France = "fr",
  Gabon = "ga",
  UnitedKingdom = "gb",
  Grenada = "gd",
  Georgia = "ge",
  FrenchGuiana = "gf",
  Guernsey = "gg",
  Ghana = "gh",
  Gibraltar = "gi",
  Greenland = "gl",
  Gambia = "gm",
  Guinea = "gn",
  Guadeloupe = "gp",
  EquatorialGuinea = "gq",
  Greece = "gr",
  SouthGeorgiaAndTheSouthSandwichIslands = "gs",
  Guatemala = "gt",
  Guam = "gu",
  GuineaBissau = "gw",
  Guyana = "gy",
  HongKong = "hk",
  HeardIslandAndMcDonaldIslands = "hm",
  Honduras = "hn",
  Croatia = "hr",
  Haiti = "ht",
  Hungary = "hu",
  Indonesia = "id",
  Ireland = "ie",
  Israel = "il",
  IsleOfMan = "im",
  India = "in",
  BritishIndianOceanTerritory = "io",
  Iraq = "iq",
  Iran = "ir",
  Iceland = "is",
  Italy = "it",
  Jersey = "je",
  Jamaica = "jm",
  Jordan = "jo",
  Japan = "jp",
  Kenya = "ke",
  Kyrgyzstan = "kg",
  Cambodia = "kh",
  Kiribati = "ki",
  Comoros = "km",
  SaintKittsAndNevis = "kn",
  NorthKorea = "kp",
  SouthKorea = "kr",
  Kuwait = "kw",
  CaymanIslands = "ky",
  Kazakhstan = "kz",
  Laos = "la",
  Lebanon = "lb",
  SaintLucia = "lc",
  Liechtenstein = "li",
  SriLanka = "lk",
  Liberia = "lr",
  Lesotho = "ls",
  Lithuania = "lt",
  Luxembourg = "lu",
  Latvia = "lv",
  LibyanArabJamahiriya = "ly",
  Morocco = "ma",
  Monaco = "mc",
  Moldova = "md",
  Montenegro = "me",
  Madagascar = "mg",
  MarshallIslands = "mh",
  Macedonia = "mk",
  Mali = "ml",
  Myanmar = "mm",
  Mongolia = "mn",
  Macao = "mo",
  NorthernMarianaIslands = "mp",
  Martinique = "mq",
  Mauritania = "mr",
  Montserrat = "ms",
  Malta = "mt",
  Mauritius = "mu",
  Maldives = "mv",
  Malawi = "mw",
  Mexico = "mx",
  Malaysia = "my",
  Mozambique = "mz",
  Namibia = "na",
  NewCaledonia = "nc",
  Niger = "ne",
  NorfolkIsland = "nf",
  Nigeria = "ng",
  Nicaragua = "ni",
  Netherlands = "nl",
  Norway = "no",
  Nepal = "np",
  Nauru = "nr",
  Niue = "nu",
  NewZealand = "nz",
  Oman = "om",
  Panama = "pa",
  Peru = "pe",
  FrenchPolynesia = "pf",
  PapuaNewGuinea = "pg",
  Philippines = "ph",
  Pakistan = "pk",
  Poland = "pl",
  SaintPierreAndMiquelon = "pm",
  Pitcairn = "pn",
  PuertoRico = "pr",
  PalestinianTerritory = "ps",
  Portugal = "pt",
  Palau = "pw",
  Paraguay = "py",
  Qatar = "qa",
  Reunion = "re",
  Romania = "ro",
  Serbia = "rs",
  RussianFederation = "ru",
  Rwanda = "rw",
  SaudiArabia = "sa",
  SolomonIslands = "sb",
  Seychelles = "sc",
  Sudan = "sd",
  Sweden = "se",
  Singapore = "sg",
  SaintHelena = "sh",
  Slovenia = "si",
  SvalbardAndJanMayen = "sj",
  Slovakia = "sk",
  SierraLeone = "sl",
  SanMarino = "sm",
  Senegal = "sn",
  Somalia = "so",
  Suriname = "sr",
  SaoTomeAndPrincipe = "st",
  ElSalvador = "sv",
  SyrianArabRepublic = "sy",
  Swaziland = "sz",
  TurksAndCaicosIslands = "tc",
  Chad = "td",
  FrenchSouthernTerritories = "tf",
  Togo = "tg",
  Thailand = "th",
  Tajikistan = "tj",
  Tokelau = "tk",
  TimorLeste = "tl",
  Turkmenistan = "tm",
  Tunisia = "tn",
  Tonga = "to",
  Turkey = "tr",
  TrinidadAndTobago = "tt",
  Tuvalu = "tv",
  Taiwan = "tw",
  Tanzania = "tz",
  Ukraine = "ua",
  Uganda = "ug",
  UnitedStatesMinorOutlyingIslands = "um",
  UnitedStates = "us",
  Uruguay = "uy",
  Uzbekistan = "uz",
  VaticanCityState = "va",
  SaintVincentAndTheGrenadines = "vc",
  Venezuela = "ve",
  VirginIslandsBritish = "vg",
  VirginIslandsUS = "vi",
  VietNam = "vn",
  Vanuatu = "vu",
  WallisAndFutuna = "wf",
  Samoa = "ws",
  Yemen = "ye",
  Mayotte = "yt",
  SouthAfrica = "za",
  Zambia = "zm",
  Zimbabwe = "zw",
}

export enum Region {
  Apac = "apac",
  Emea = "emea",
  Us = "us",
  Latam = "latam",
  Na = "na",
}
