
import TheSnackbar from "@/components/TheSnackbar"
import TheArchiveProjectSnackbar from "@/components/TheArchiveProjectSnackbar"
import TheUsersTabs from "@/components/TheUsersTabs"
import TheLeftSidebar from "@/components/TheLeftSidebar"
import TheRightSidebar from "@/components/TheRightSidebar"
import TheGlobalDialogs from "@/components/TheGlobalDialogs"
import TheAppBar from "@/components/appBar/TheAppBar"
import TheCameraTabs from "@/components/TheCameraTabs"
import TheCopilotCallout from "@/components/TheCopilotCallout"
import ConfirmDialog from "@evercam/shared/components/ConfirmDialog"
import ConnectorPromptDialog from "@/components/connectors/ConnectorPromptDialog"

import Vue from "vue"
import { useSnapshotStore } from "@/stores/snapshots"
import { useRecordingsStore } from "@/stores/recordings"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useConnectorStore } from "@/stores/connector"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import { camelizeKeys } from "humps"

export default Vue.extend({
  components: {
    TheLeftSidebar,
    TheRightSidebar,
    TheGlobalDialogs,
    TheAppBar,
    TheCameraTabs,
    TheSnackbar,
    TheUsersTabs,
    TheCopilotCallout,
    ConfirmDialog,
    ConnectorPromptDialog,
    TheArchiveProjectSnackbar,
  },
  middleware: ["unitSystemLookup"],
  data() {
    return {
      contentHeight: "100%",
    }
  },
  head() {
    return {
      meta: [
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, maximum-scale=1",
        },
      ],
    }
  },
  computed: {
    ...mapStores(
      useSnapshotStore,
      useLayoutStore,
      useConnectorStore,
      useRecordingsStore,
      useCameraStore,
      useAccountStore,
      useProjectStore
    ),
    camera(): string | undefined {
      return camelizeKeys(this.$route.params)?.cameraExid
    },
    project(): string | undefined {
      return camelizeKeys(this.$route.params)?.projectExid
    },
    isArchiveProject() {
      return this.projectStore.isArchiveProject
    },
    page(): string | undefined {
      return this.$route?.fullPath.split("/")[2]
    },
    contentStyle(): Record<string, any> {
      if (this.layoutStore.mainContentRect?.height) {
        return {
          height: `${this.layoutStore.mainContentRect.height}px`,
        }
      }

      return {}
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.updateLayoutStore()
        this.$setTimeout(
          () => this.$refs.mainContent?.scrollTo({ top: 0 }),
          200
        )
      },
    },
  },
  async created() {
    if (this.accountStore.token) {
      await this.accountStore.updateFeaturesAccess()
      this.connectorStore.getConnectors()
    }
  },
  mounted() {
    this.$setTimeout(() => this.$theme.refreshThemeMode(), 0)
    this.$addEventListener("keydown", this.onKeyHandler)
    this.$addEventListener("keyup", this.onKeyHandler)
    this.updateLayoutStore()
  },
  methods: {
    onKeyHandler(e) {
      const ctrlKey = e.ctrlKey && e.type === "keydown"
      const shiftKey = e.shiftKey && e.type === "keydown"
      const x = ["x", "X"].includes(e.key) && e.type === "keydown"

      if (x && shiftKey && ctrlKey) {
        this.$nextTick(() => {
          this.$theme.switchTheme({ isDark: !this.$vuetify.theme.dark })
        })
      }
    },
    updateLayoutStore() {
      this.$nextTick(() => {
        const contentElement = this.$refs.mainContent
        if (!contentElement) {
          return
        }
        const contentRect = contentElement.getBoundingClientRect()
        const x = contentRect.x
        const y = contentRect.y
        const width = window.innerWidth - contentRect.left
        const height = window.innerHeight - contentRect.top

        this.layoutStore.mainContentRect = new DOMRect(x, y, width, height)

        if (this.layoutStore.isCleanMode) {
          this.layoutStore.enableCleanMode()
        }
      })
    },
  },
})
