
import Vue, { PropType } from "vue"
import { Media, MediaType } from "@evercam/shared/types/media"

export default Vue.extend({
  name: "MediaOverviewContent",
  props: {
    mediaItem: {
      type: Object as PropType<Media>,
      default: () => ({} as Media),
    },
    mediaUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      videoNotFound: false,
    }
  },

  computed: {
    isImage(): boolean {
      return (
        this.isEditedImageMediaType ||
        this.isXrayImageMediaType ||
        (this.isFileMediaType && this.getFileType(this.mediaUrl) === "image")
      )
    },
    isEditedImageMediaType(): boolean {
      return this.mediaItem.type === MediaType.EditedImage
    },
    isXrayImageMediaType(): boolean {
      return this.mediaItem.type === MediaType.XRay
    },
    isFileMediaType(): boolean {
      return this.mediaItem.type === MediaType.File
    },
    isExternalUrlMediaType(): boolean {
      return this.mediaItem.type === MediaType.ExternalUrl
    },
    isDocFile(): boolean {
      return ["pdf", "doc", "docx", "csv", "pptx", "xlsx"].includes(
        this.mediaItem.fileName?.split(".").pop() || ""
      )
    },
    playerOptions(): Record<string, string | boolean> {
      return {
        height: "360",
        muted: true,
        autoplay: true,
        loop: true,
        aspectRatio: "16:9",
        language: "en",
        responsive: true,
      }
    },
    sources(): Array<Record<string, string>> {
      return [
        {
          type: "video/mp4",
          src: this.mediaUrl,
        },
      ]
    },
  },
  methods: {
    getFileType(extension: string): string {
      let imageExt = ["jpg", "jpeg", "bmp", "gif", "png"]
      let videoExt = ["mp4", "ogg", "webm", "avi", "flv", "wmv", "mov"]
      const regex = /\.([a-zA-Z0-9]+)(?:$|\?)/
      const match = extension.match(regex)
      if (match) {
        const extractedExt = match[1].toLowerCase()

        if (imageExt.includes(extractedExt)) {
          return "image"
        } else if (videoExt.includes(extractedExt)) {
          return "video"
        }
      }

      return "unknown"
    },
    onError(error: Error): void {
      this.videoNotFound = true
      this.$notifications.error({
        text: this.$t("content.media_hub.video_not_found"),
        error,
      })
    },
  },
})
