import { render, staticRenderFns } from "./UserActionsMenu.vue?vue&type=template&id=6d360c70&scoped=true"
import script from "./UserActionsMenu.vue?vue&type=script&lang=js"
export * from "./UserActionsMenu.vue?vue&type=script&lang=js"
import style0 from "./UserActionsMenu.vue?vue&type=style&index=0&id=6d360c70&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d360c70",
  null
  
)

export default component.exports