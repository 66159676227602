
import { timeouts } from "@evercam/ui"
import SnapshotEditor from "@/components/SnapshotEditor"
import {
  MESSAGES_TYPES,
  TAGS_TYPES,
  PANO_VIEWER_TYPE,
} from "@evercam/3d/components/constants"
import CompareExportDialog from "@/components/CompareExportDialog"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import FrameViewer from "@/components/3dAppsDialogs/FrameViewer"
import RecordingsDialog from "@/components/3dAppsDialogs/RecordingsDialog"
import CreateUploadDialog from "@/components/ingest/uploadDialogs/CreateUploadDialog"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { mapStores } from "pinia"
import { useWeatherStore } from "@evercam/dashboard/stores/weather"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { camelizeKeys } from "humps"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  InfoPage,
  WatermarkedImageDownloadOrigin,
} from "@evercam/shared/types"
import ContextMenu from "@/components/3dAppsDialogs/ContextMenu"
import { useLayoutStore } from "@/stores/layout"
import { useConnectorStore } from "@/stores/connector"

export default {
  name: "DroneView",
  meta: {
    pageId: AnalyticsEventPageId.Drone,
  },
  components: {
    SnapshotEditor,
    ContextMenu,
    FrameViewer,
    RecordingsDialog,
    CompareExportDialog,
    CreateUploadDialog,
  },
  mixins: [timeouts],
  middleware({ redirect, $permissions }) {
    const projectStore = useProjectStore()

    if (!$permissions.project.has.droneView()) {
      redirect(`${projectStore.projectRoute}/info/${InfoPage.DroneView}`)
    }
  },
  data: () => ({
    recordingsCameraExid: undefined,
    gateReportCameraExid: undefined,
    isPanoViewEnabled: false,
    panoViewerMode: null,
    isEditingImage: false,
    currentSnapshot: null,
    cameraContextMenuInfo: null,
    compareOptions: {},
    droneViewExists: false,
    ingestUploadTypes: INGEST_UPLOADS_TYPES,
    droneUploadObject: undefined,
    droneUploadDialog: false,
    initialQueryParams: {},
    droneData: {},
    WatermarkedImageDownloadOrigin,
  }),
  head() {
    return {
      title: `${
        this.projectStore.selectedProjectName || "Evercam"
      } | Drone View`,
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
    ...mapStores(
      useWeatherStore,
      useProjectStore,
      useCameraStore,
      useAccountStore,
      useIngestFileUploaderStore,
      useLayoutStore,
      useConnectorStore
    ),
    firstCamera() {
      return this.projectStore.selectedProjectCameras[0] || {}
    },
    routeProjectExid() {
      return camelizeKeys(this.$route.params)?.projectExid
    },
    params() {
      return (
        "3d?" +
        new URLSearchParams({
          ...this.initialQueryParams,
          projectID: this.routeProjectExid,
          type: "site-view",
          unit: this.accountStore.unitSystem,
        })
      )
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.initialQueryParams = { ...this.$route.query, user: "user" }
    this.saveAnalyticsEvent("Open")
    this.getDroneModels()
  },
  methods: {
    async onMessageReceived(data) {
      const messageType = data?.type
      switch (messageType) {
        case MESSAGES_TYPES.analyticsEvent:
          this.saveAnalyticsEvent(data?.action)
          break
        case MESSAGES_TYPES.liveView:
          this.openRecordings(data?.cam)
          break
        case MESSAGES_TYPES.ready:
          this.sendProjectInfo(data?.origin)
          break
        case MESSAGES_TYPES.editSnapshot:
          if (!this.isEditingImage) {
            this.openImageEditor(data?.url, data?.droneData)
          }
          break

        case MESSAGES_TYPES.weather:
          this.weatherStore.targetTimestamp = data.timestamp
          this.weatherStore.changeWeatherVisibility(true)
          break
        case MESSAGES_TYPES.cameraInfo:
          {
            const cameraInfoType = data?.data?.type

            if (cameraInfoType === TAGS_TYPES.aerialshot) {
              this.panoViewerMode = PANO_VIEWER_TYPE.aerialshot
              this.openCameraContextMenu(data.position, null, data.data)
            } else if (cameraInfoType === PANO_VIEWER_TYPE._360Path) {
              this.panoViewerMode = PANO_VIEWER_TYPE._360Path
              this.openCameraContextMenu(data.position, null, data.data)
            } else {
              // This case is for cameras, gate cameras & indoor cameras
              this.openCameraContextMenu(data.position, data.cam, data.data)
            }
          }
          break
        case MESSAGES_TYPES.createCompare:
          this.compareOptions = data.data
          this.$root.$emit("toggle-compare-dialogue")
          break
        case MESSAGES_TYPES.uploadDrone:
          await this.generateDroneUploadLink()
          break
        case MESSAGES_TYPES.history:
          if (data.origin === this.$config.public.bimIframeUrl) {
            this.pushSearchParams(data.history)
          }
          break
        case MESSAGES_TYPES.shareLink:
          this.shareLink()
          break
        default:
          break
      }
    },
    openRecordings(cameraExid) {
      this.cameraContextMenuInfo = null
      this.recordingsCameraExid = null
      this.$setTimeout(() => {
        this.recordingsCameraExid = cameraExid
      })
    },
    saveAnalyticsEvent(action) {
      this.$analytics.saveEvent(`${action}`) // The analytics method is already handling the pageId
    },
    async sendProjectInfo(origin) {
      const frameViewerRef = this.$refs.frameViewer // This could be Pano-iFrame or Drone-iFrame
      const frameViewerContainer = frameViewerRef?.$refs.container
      frameViewerContainer?.contentWindow?.postMessage(
        {
          type: MESSAGES_TYPES.info,
          cameras: this.projectStore.selectedProjectCameras.reduce(
            (acc, value) => {
              acc[value.id] = value

              return acc
            },
            {}
          ),
          user: {
            email: this.accountStore.email,
            name: this.accountStore.fullname,
          },
        },
        origin
      )
    },
    async openCameraContextMenu(position, cameraExid, data) {
      this.cameraContextMenuInfo = {
        x: position.x,
        y: position.y,
        camera: cameraExid,
        data,
      }
    },
    openImageEditor(urlData, droneData) {
      this.$analytics.saveEvent(AnalyticsEvent.MarkUpToolOpen)
      this.currentSnapshot = {
        snapshot: {
          data: urlData,
          createdAt: new Date(),
        },
        camera: this.firstCamera,
      }
      this.isEditingImage = true
      this.droneData = droneData
    },
    openPanoView(metadata) {
      const markerData = metadata.pano.split("-")
      this.currentAerialShot = {
        date_id: markerData[0],
        floor: markerData[1],
        marker: markerData[2],
      }
      this.isPanoViewEnabled = true
      this.cameraContextMenuInfo = null
    },
    closePanoView() {
      this.isPanoViewEnabled = false
      this.panoViewerMode = null
    },
    async getDroneModels() {
      let response = await fetch(
        this.$config.public.firebaseDbLink +
          `data/projects/cesium/${this.projectStore.selectedProjectExid}/models.json`
      )
      const droneModels = await response.json()
      this.droneViewExists = droneModels && droneModels.length > 0
    },
    async generateDroneUploadLink() {
      if (!this.projectStore.selectedProjectExid) {
        return
      }
      try {
        this.droneUploadObject = await IngestApi.drone.createUpload(
          this.projectStore.selectedProjectExid,
          {
            uploadedBy: this.accountStore.email,
          }
        )
        const uploadStats = {
          percentage: 0,
          totalSize: 0,
          uploadedSize: 0,
          isUploading: false,
          isProcessing: false,
          uploadFinished: false,
          uploadType: INGEST_UPLOADS_TYPES.droneUploads,
        }
        this.ingestFileUploaderStore.currentUploadStats = uploadStats
        this.ingestFileUploaderStore.uploadStats.push(uploadStats)
        this.droneUploadDialog = true
      } catch (e) {
        this.$notifications.error({ text: "Couldn't get token : ", error: e })
      } finally {
        this.saveAnalyticsEvent(AnalyticsEvent.DroneUploadFlight)
      }
    },
    onDroneUplaodDialogClosed() {
      this.droneUploadDialog = false
      this.droneUploadObject = undefined
    },
    pushSearchParams(parameters) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          ...parameters,
        },
      })
    },
    shareLink() {
      this.saveAnalyticsEvent(AnalyticsEvent.DroneShareLink)
      const link = window.location.href
      navigator.clipboard.writeText(link)
      this.$notifications.success("Link copied to clipboard.")
    },
  },
}
