
import NdaIcon from "@/components/NdaIcon"
import Vue, { PropType } from "vue"
import { Project, ProjectFeatureFlag } from "@evercam/shared/types/project"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { _3dViewer } from "@evercam/shared/types"
import { useAccountStore } from "@/stores/account"

export default Vue.extend({
  name: "ProjectCard",
  components: { NdaIcon },
  props: {
    project: {
      type: Object as PropType<Project>,
      default: () => ({} as Project),
    },
  },
  data() {
    return {
      url: "" as string,
      isActive: false as boolean,
      viewer: _3dViewer,
    }
  },
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
    hasEllipsis() {
      let el = this.$refs?.ellipsis?.$el
      if (el) {
        return el.offsetWidth < el.scrollWidth
      } else {
        return false
      }
    },
    isUnderNda() {
      return this.project.featureFlags.includes(ProjectFeatureFlag.NdaMc)
    },
    camerasCountColor(): string {
      return this.$vuetify.theme.dark ? "#10131b" : "grey darken-1"
    },
  },
  watch: {
    "projectStore.selectedProject": {
      deep: true,
      handler() {
        window.stop()
      },
    },
  },
  mounted() {
    this.url = `${this.$config.public.apiURL}/projects/${this.project.exid}/thumbnail?authorization=${this.accountStore.token}`
  },
  methods: {
    onError() {
      this.url = require("~/assets/img/waiting_for_activation.jpg")
      this.$emit("error", this.url)
    },
    setProjectStatus(project) {
      if (project.status === "Completed") {
        return `Archived ${project.archiveTier}`
      } else {
        return project.status
      }
    },
    getHoverStyle(hover = false) {
      if (this.$vuetify.theme.dark) {
        return `${hover ? "hovered" : ""} lighten-${hover ? "2" : "1"}`
      }

      return `${hover ? "hovered" : ""} darken-${hover ? "2" : "1"}`
    },
    getIconColor(enabledFeature) {
      if (enabledFeature) {
        return this.$vuetify.theme.currentTheme.on_background
      }

      return this.$vuetify.theme.dark ? "rgba(218,221,227,0.3)" : "#757575"
    },
  },
})
