import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { useLayoutStore } from "@/stores/layout"
import { camelizeKeys } from "humps"
import { defineNuxtRouteMiddleware, navigateTo } from "#app"
import { CameraExid, ProjectExid, RouteParams } from "@evercam/shared/types"
import { withProgress } from "@evercam/shared/utils"

export default defineNuxtRouteMiddleware(async (to) => {
  const { isWidget } = camelizeKeys(to.query) as Record<string, string>
  const accountStore = useAccountStore()
  const layoutStore = useLayoutStore()
  const routeParams = camelizeKeys(to.params) as RouteParams
  const routeProjectExid = routeParams.projectExid
  const routeCameraExid = routeParams.cameraExid
  const isPublicPage = to.meta[0]?.publiclyAccessible
  const isUnauthenticated = isWidget || !accountStore.token
  layoutStore.isEvercamWatermark = to.query.watermark
    ? to.query.watermark === "true"
    : true

  if (isUnauthenticated) {
    await maybeRedirectToLoginPage(routeCameraExid, isPublicPage)

    return
  }
  preventReloadAttempt()
  await ensureUserData()

  await Promise.all([ensureUserProjects(), ensureUserCameras()])

  await Promise.all([
    ensureSelectedCamera(routeCameraExid),
    ensureSelectedProject(routeProjectExid),
  ])
})

async function ensureUserProjects() {
  const projectStore = useProjectStore()
  if (!projectStore.projects?.length && !projectStore.isFetchingProjects) {
    await withProgress("ensure_projects", projectStore.fetchUserProjects)
  }
}

async function ensureUserCameras() {
  const cameraStore = useCameraStore()

  if (
    !cameraStore.cameras?.length &&
    !cameraStore.isFetchingUserCameras &&
    !cameraStore.isCameraPublic
  ) {
    await withProgress("ensure_cameras", cameraStore.fetchUserCameras)
  }
}

async function ensureSelectedCamera(cameraExid: CameraExid) {
  const cameraStore = useCameraStore()
  if (
    cameraExid &&
    (!cameraStore.selectedCamera ||
      cameraExid !== cameraStore.selectedCamera.id)
  ) {
    await withProgress("select_camera", async () =>
      cameraStore.selectCamera(cameraExid)
    )
  }
}

async function ensureSelectedProject(projectExid: ProjectExid) {
  const projectStore = useProjectStore()
  if (projectExid && projectExid !== projectStore.selectedProjectExid) {
    await withProgress("select_project", async () =>
      projectStore.selectProject(projectExid)
    )
  }
}

async function ensureUserData() {
  const accountStore = useAccountStore()
  if (!accountStore.email) {
    await withProgress("ensure_user", accountStore.fetchUserData)
  }
}

async function maybeRedirectToLoginPage(
  routeCameraExid: CameraExid,
  isPublicPage: boolean
) {
  await ensureSelectedCamera(routeCameraExid)
  const shouldRedirect =
    routeCameraExid && (!useCameraStore().isCameraPublic || !isPublicPage)

  if (shouldRedirect) {
    return navigateTo("/v2/users/signin")
  }
}

function preventReloadAttempt() {
  window.localStorage.setItem("ec_reloaded", "true")
}
