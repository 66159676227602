import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _40160d03 = () => interopDefault(import('../pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _1d69032b = () => interopDefault(import('../pages/good-bye.vue' /* webpackChunkName: "pages/good-bye" */))
const _3f4b9f2a = () => interopDefault(import('../pages/pay.vue' /* webpackChunkName: "pages/pay" */))
const _13298baf = () => interopDefault(import('../pages/server-down.vue' /* webpackChunkName: "pages/server-down" */))
const _db9ea768 = () => interopDefault(import('../pages/v2/index.vue' /* webpackChunkName: "pages/v2/index" */))
const _46063d52 = () => interopDefault(import('../pages/auth/evercam.vue' /* webpackChunkName: "pages/auth/evercam" */))
const _cd6bfc12 = () => interopDefault(import('../pages/v2/company.vue' /* webpackChunkName: "pages/v2/company" */))
const _79eee456 = () => interopDefault(import('../pages/v2/map.vue' /* webpackChunkName: "pages/v2/map" */))
const _f8c4b91a = () => interopDefault(import('../pages/v2/projects/index.vue' /* webpackChunkName: "pages/v2/projects/index" */))
const _0950e47c = () => interopDefault(import('../pages/v2/site-planner.vue' /* webpackChunkName: "pages/v2/site-planner" */))
const _0839eb9f = () => interopDefault(import('../pages/v2/team-members.vue' /* webpackChunkName: "pages/v2/team-members" */))
const _6d93f912 = () => interopDefault(import('../pages/v2/tutorials/index.vue' /* webpackChunkName: "pages/v2/tutorials/index" */))
const _dd5fb56a = () => interopDefault(import('../pages/v2/unsubscribe.vue' /* webpackChunkName: "pages/v2/unsubscribe" */))
const _0c990554 = () => interopDefault(import('../pages/v2/video-walls/index.vue' /* webpackChunkName: "pages/v2/video-walls/index" */))
const _53260eec = () => interopDefault(import('../pages/v2/widgets/index.vue' /* webpackChunkName: "pages/v2/widgets/index" */))
const _8416726a = () => interopDefault(import('../pages/v2/automations/progress-photos/index.vue' /* webpackChunkName: "pages/v2/automations/progress-photos/index" */))
const _2fbb04a0 = () => interopDefault(import('../pages/v2/users/account.vue' /* webpackChunkName: "pages/v2/users/account" */))
const _c687b06c = () => interopDefault(import('../pages/v2/users/activities.vue' /* webpackChunkName: "pages/v2/users/activities" */))
const _2bfa25ba = () => interopDefault(import('../pages/v2/users/connectors.vue' /* webpackChunkName: "pages/v2/users/connectors" */))
const _5936b6f8 = () => interopDefault(import('../pages/v2/users/embedded-bim-compare.vue' /* webpackChunkName: "pages/v2/users/embedded-bim-compare" */))
const _9e7e3980 = () => interopDefault(import('../pages/v2/users/embedded-live-view.vue' /* webpackChunkName: "pages/v2/users/embedded-live-view" */))
const _66b3b5b8 = () => interopDefault(import('../pages/v2/users/embedded-recordings.vue' /* webpackChunkName: "pages/v2/users/embedded-recordings" */))
const _678f2a88 = () => interopDefault(import('../pages/v2/users/integrations.vue' /* webpackChunkName: "pages/v2/users/integrations" */))
const _b6d90cea = () => interopDefault(import('../pages/v2/users/password-new.vue' /* webpackChunkName: "pages/v2/users/password-new" */))
const _ae9b160c = () => interopDefault(import('../pages/v2/users/password-reset.vue' /* webpackChunkName: "pages/v2/users/password-reset" */))
const _245fb902 = () => interopDefault(import('../pages/v2/users/signin.vue' /* webpackChunkName: "pages/v2/users/signin" */))
const _026679f5 = () => interopDefault(import('../pages/v2/users/signup.vue' /* webpackChunkName: "pages/v2/users/signup" */))
const _73bd4fbc = () => interopDefault(import('../pages/v2/users/widgets.vue' /* webpackChunkName: "pages/v2/users/widgets" */))
const _d391a490 = () => interopDefault(import('../pages/v2/widgets/bim-compare.vue' /* webpackChunkName: "pages/v2/widgets/bim-compare" */))
const _0faa6e02 = () => interopDefault(import('../pages/v2/widgets/compare.vue' /* webpackChunkName: "pages/v2/widgets/compare" */))
const _3cf00a00 = () => interopDefault(import('../pages/v2/widgets/live-view.vue' /* webpackChunkName: "pages/v2/widgets/live-view" */))
const _606966f8 = () => interopDefault(import('../pages/v2/widgets/recordings.vue' /* webpackChunkName: "pages/v2/widgets/recordings" */))
const _7213e4d5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1e083496 = () => interopDefault(import('../pages/v2/automations/progress-photos/_id/unsubscribe.vue' /* webpackChunkName: "pages/v2/automations/progress-photos/_id/unsubscribe" */))
const _49fbf0f7 = () => interopDefault(import('../pages/v2/projects/_project_exid/index.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/index" */))
const _6bdd57ca = () => interopDefault(import('../pages/v2/tutorials/_slug.vue' /* webpackChunkName: "pages/v2/tutorials/_slug" */))
const _6ccf4dd6 = () => interopDefault(import('../pages/v2/projects/_project_exid/360/index.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/360/index" */))
const _300d9282 = () => interopDefault(import('../pages/v2/projects/_project_exid/3d/index.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/3d/index" */))
const _93bd6b2a = () => interopDefault(import('../pages/v2/projects/_project_exid/bim.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/bim" */))
const _585ee43d = () => interopDefault(import('../pages/v2/projects/_project_exid/drone.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/drone" */))
const _7e3200db = () => interopDefault(import('../pages/v2/projects/_project_exid/gate-report.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/gate-report" */))
const _22ed507e = () => interopDefault(import('../pages/v2/projects/_project_exid/map.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/map" */))
const _1b2491f4 = () => interopDefault(import('../pages/v2/projects/_project_exid/media-hub/index.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/media-hub/index" */))
const _a681a0e4 = () => interopDefault(import('../pages/v2/projects/_project_exid/settings.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/settings" */))
const _dc3d58de = () => interopDefault(import('../pages/v2/projects/_project_exid/settings/index.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/settings/index" */))
const _a287916c = () => interopDefault(import('../pages/v2/projects/_project_exid/settings/camera-settings.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/settings/camera-settings" */))
const _3d746ad0 = () => interopDefault(import('../pages/v2/projects/_project_exid/settings/members.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/settings/members" */))
const _2c80c2b7 = () => interopDefault(import('../pages/v2/projects/_project_exid/site-planner.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/site-planner" */))
const _375feea8 = () => interopDefault(import('../pages/v2/projects/_project_exid/timeline.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/timeline" */))
const _2f9e4bf2 = () => interopDefault(import('../pages/v2/projects/_project_exid/video-wall.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/video-wall" */))
const _08855f10 = () => interopDefault(import('../pages/v2/projects/_project_exid/info/_page.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/info/_page" */))
const _0335253e = () => interopDefault(import('../pages/v2/projects/_project_exid/media-hub/_mediaExid.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/media-hub/_mediaExid" */))
const _2ee8c297 = () => interopDefault(import('../pages/v2/projects/_project_exid/_camera_exid/index.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/_camera_exid/index" */))
const _a0e03186 = () => interopDefault(import('../pages/v2/projects/_project_exid/_camera_exid/404.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/_camera_exid/404" */))
const _69b13523 = () => interopDefault(import('../pages/v2/projects/_project_exid/_camera_exid/bim-compare.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/_camera_exid/bim-compare" */))
const _23e1842c = () => interopDefault(import('../pages/v2/projects/_project_exid/_camera_exid/compare.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/_camera_exid/compare" */))
const _a5389220 = () => interopDefault(import('../pages/v2/projects/_project_exid/_camera_exid/recordings/index.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/_camera_exid/recordings/index" */))
const _1ac06a7f = () => interopDefault(import('../pages/v2/projects/_project_exid/_camera_exid/shares.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/_camera_exid/shares" */))
const _04294ec5 = () => interopDefault(import('../pages/v2/projects/_project_exid/_camera_exid/timelapse.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/_camera_exid/timelapse" */))
const _2e890090 = () => interopDefault(import('../pages/v2/projects/_project_exid/_camera_exid/recordings/snapshots/index.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/_camera_exid/recordings/snapshots/index" */))
const _31c5bea4 = () => interopDefault(import('../pages/v2/projects/_project_exid/_camera_exid/share/request.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/_camera_exid/share/request" */))
const _0403876e = () => interopDefault(import('../pages/v2/projects/_project_exid/_camera_exid/recordings/snapshots/_timestamp.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/_camera_exid/recordings/snapshots/_timestamp" */))
const _003282d8 = () => interopDefault(import('../pages/v2/projects/_project_exid/_camera_exid/info/_page.vue' /* webpackChunkName: "pages/v2/projects/_project_exid/_camera_exid/info/_page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/confirm",
    component: _40160d03,
    name: "confirm"
  }, {
    path: "/good-bye",
    component: _1d69032b,
    name: "good-bye"
  }, {
    path: "/pay",
    component: _3f4b9f2a,
    name: "pay"
  }, {
    path: "/server-down",
    component: _13298baf,
    name: "server-down"
  }, {
    path: "/v2",
    component: _db9ea768,
    name: "v2"
  }, {
    path: "/auth/evercam",
    component: _46063d52,
    name: "auth-evercam"
  }, {
    path: "/v2/company",
    component: _cd6bfc12,
    name: "v2-company"
  }, {
    path: "/v2/map",
    component: _79eee456,
    name: "v2-map"
  }, {
    path: "/v2/projects",
    component: _f8c4b91a,
    name: "v2-projects"
  }, {
    path: "/v2/site-planner",
    component: _0950e47c,
    name: "v2-site-planner"
  }, {
    path: "/v2/team-members",
    component: _0839eb9f,
    name: "v2-team-members"
  }, {
    path: "/v2/tutorials",
    component: _6d93f912,
    name: "v2-tutorials"
  }, {
    path: "/v2/unsubscribe",
    component: _dd5fb56a,
    name: "v2-unsubscribe"
  }, {
    path: "/v2/video-walls",
    component: _0c990554,
    name: "v2-video-walls"
  }, {
    path: "/v2/widgets",
    component: _53260eec,
    name: "v2-widgets"
  }, {
    path: "/v2/automations/progress-photos",
    component: _8416726a,
    name: "v2-automations-progress-photos"
  }, {
    path: "/v2/users/account",
    component: _2fbb04a0,
    name: "v2-users-account"
  }, {
    path: "/v2/users/activities",
    component: _c687b06c,
    name: "v2-users-activities"
  }, {
    path: "/v2/users/connectors",
    component: _2bfa25ba,
    name: "v2-users-connectors"
  }, {
    path: "/v2/users/embedded-bim-compare",
    component: _5936b6f8,
    name: "v2-users-embedded-bim-compare"
  }, {
    path: "/v2/users/embedded-live-view",
    component: _9e7e3980,
    name: "v2-users-embedded-live-view"
  }, {
    path: "/v2/users/embedded-recordings",
    component: _66b3b5b8,
    name: "v2-users-embedded-recordings"
  }, {
    path: "/v2/users/integrations",
    component: _678f2a88,
    name: "v2-users-integrations"
  }, {
    path: "/v2/users/password-new",
    component: _b6d90cea,
    name: "v2-users-password-new"
  }, {
    path: "/v2/users/password-reset",
    component: _ae9b160c,
    name: "v2-users-password-reset"
  }, {
    path: "/v2/users/signin",
    component: _245fb902,
    name: "v2-users-signin"
  }, {
    path: "/v2/users/signup",
    component: _026679f5,
    name: "v2-users-signup"
  }, {
    path: "/v2/users/widgets",
    component: _73bd4fbc,
    name: "v2-users-widgets"
  }, {
    path: "/v2/widgets/bim-compare",
    component: _d391a490,
    name: "v2-widgets-bim-compare"
  }, {
    path: "/v2/widgets/compare",
    component: _0faa6e02,
    name: "v2-widgets-compare"
  }, {
    path: "/v2/widgets/live-view",
    component: _3cf00a00,
    name: "v2-widgets-live-view"
  }, {
    path: "/v2/widgets/recordings",
    component: _606966f8,
    name: "v2-widgets-recordings"
  }, {
    path: "/",
    component: _7213e4d5,
    name: "index"
  }, {
    path: "/v2/automations/progress-photos/:id?/unsubscribe",
    component: _1e083496,
    name: "v2-automations-progress-photos-id-unsubscribe"
  }, {
    path: "/v2/projects/:project_exid",
    component: _49fbf0f7,
    name: "v2-projects-project_exid"
  }, {
    path: "/v2/tutorials/:slug",
    component: _6bdd57ca,
    name: "v2-tutorials-slug"
  }, {
    path: "/v2/projects/:project_exid/360",
    component: _6ccf4dd6,
    name: "v2-projects-project_exid-360"
  }, {
    path: "/v2/projects/:project_exid/3d",
    component: _300d9282,
    name: "v2-projects-project_exid-3d"
  }, {
    path: "/v2/projects/:project_exid/bim",
    component: _93bd6b2a,
    name: "v2-projects-project_exid-bim"
  }, {
    path: "/v2/projects/:project_exid/drone",
    component: _585ee43d,
    name: "v2-projects-project_exid-drone"
  }, {
    path: "/v2/projects/:project_exid/gate-report",
    component: _7e3200db,
    name: "v2-projects-project_exid-gate-report"
  }, {
    path: "/v2/projects/:project_exid/map",
    component: _22ed507e,
    name: "v2-projects-project_exid-map"
  }, {
    path: "/v2/projects/:project_exid/media-hub",
    component: _1b2491f4,
    name: "v2-projects-project_exid-media-hub"
  }, {
    path: "/v2/projects/:project_exid/settings",
    component: _a681a0e4,
    children: [{
      path: "",
      component: _dc3d58de,
      name: "v2-projects-project_exid-settings"
    }, {
      path: "camera-settings",
      component: _a287916c,
      name: "v2-projects-project_exid-settings-camera-settings"
    }, {
      path: "members",
      component: _3d746ad0,
      name: "v2-projects-project_exid-settings-members"
    }]
  }, {
    path: "/v2/projects/:project_exid/site-planner",
    component: _2c80c2b7,
    name: "v2-projects-project_exid-site-planner"
  }, {
    path: "/v2/projects/:project_exid/timeline",
    component: _375feea8,
    name: "v2-projects-project_exid-timeline"
  }, {
    path: "/v2/projects/:project_exid/video-wall",
    component: _2f9e4bf2,
    name: "v2-projects-project_exid-video-wall"
  }, {
    path: "/v2/projects/:project_exid/info/:page?",
    component: _08855f10,
    name: "v2-projects-project_exid-info-page"
  }, {
    path: "/v2/projects/:project_exid/media-hub/:mediaExid?",
    component: _0335253e,
    name: "v2-projects-project_exid-media-hub-mediaExid"
  }, {
    path: "/v2/projects/:project_exid/:camera_exid",
    component: _2ee8c297,
    name: "v2-projects-project_exid-camera_exid"
  }, {
    path: "/v2/projects/:project_exid/:camera_exid/404",
    component: _a0e03186,
    name: "v2-projects-project_exid-camera_exid-404"
  }, {
    path: "/v2/projects/:project_exid/:camera_exid/bim-compare",
    component: _69b13523,
    name: "v2-projects-project_exid-camera_exid-bim-compare"
  }, {
    path: "/v2/projects/:project_exid/:camera_exid/compare",
    component: _23e1842c,
    name: "v2-projects-project_exid-camera_exid-compare"
  }, {
    path: "/v2/projects/:project_exid/:camera_exid/recordings",
    component: _a5389220,
    name: "v2-projects-project_exid-camera_exid-recordings"
  }, {
    path: "/v2/projects/:project_exid/:camera_exid/shares",
    component: _1ac06a7f,
    name: "v2-projects-project_exid-camera_exid-shares"
  }, {
    path: "/v2/projects/:project_exid/:camera_exid/timelapse",
    component: _04294ec5,
    name: "v2-projects-project_exid-camera_exid-timelapse"
  }, {
    path: "/v2/projects/:project_exid/:camera_exid/recordings/snapshots",
    component: _2e890090,
    name: "v2-projects-project_exid-camera_exid-recordings-snapshots"
  }, {
    path: "/v2/projects/:project_exid/:camera_exid/share/request",
    component: _31c5bea4,
    name: "v2-projects-project_exid-camera_exid-share-request"
  }, {
    path: "/v2/projects/:project_exid/:camera_exid/recordings/snapshots/:timestamp",
    component: _0403876e,
    name: "v2-projects-project_exid-camera_exid-recordings-snapshots-timestamp"
  }, {
    path: "/v2/projects/:project_exid/:camera_exid/info/:page?",
    component: _003282d8,
    name: "v2-projects-project_exid-camera_exid-info-page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
