
import weekDays from "@evercam/shared/constants/weekDays"
import ProgressPhotosHeader from "@/components/progressPhoto/progressPhotosHeaders"
import ResizeTable from "@/mixins/resizeTable"
import { mapStores } from "pinia"
import { useProgressPhotoStore } from "@/stores/progressPhotos"
import { useConnectorStore } from "@/stores/connector"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"

import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  ProgressPhotoDialogType,
} from "@evercam/shared/types"
import ProgressPhotoDialog from "@/components/progressPhoto/ProgressPhotoDialog"
import AutomationsPlaceholder from "@/components/automations/AutomationsPlaceholder"
import { useCameraStore } from "@/stores/camera"
import { AutomationStatus } from "@evercam/shared/constants/automations"
import AutomationsFilters from "@/components/automations/AutomationsFilters"
import DefaultPageWrapper from "@/components/DefaultPageWrapper"

export default {
  meta: {
    pageId: AnalyticsEventPageId.Automations,
  },
  components: {
    ProgressPhotoDialog,
    AutomationsPlaceholder,
    AutomationsFilters,
    DefaultPageWrapper,
  },
  mixins: [ResizeTable],
  data() {
    return {
      headers: ProgressPhotosHeader,
      progressPhotosList: [],
      days: null,
      statusOptions: [AutomationStatus.Active, AutomationStatus.Paused],
      daysOptions: weekDays.map((day) => day.value),
      filters: {
        search: "",
        selectedProjects: [],
        selectedCameras: [],
        selectedUsers: [],
        selectedDays: [],
        selectedStatus: [],
      },
    }
  },
  head() {
    return {
      title: "Evercam | Progress Photos",
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(
      useProgressPhotoStore,
      useConnectorStore,
      useAccountStore,
      useProjectStore,
      useCameraStore
    ),

    filteredProgressPhotos() {
      return this.progressPhotosList.filter((item) =>
        this.matchers.every((matchFn) => matchFn(item))
      )
    },
    matchers() {
      const {
        search,
        selectedProjects,
        selectedCameras,
        selectedUsers,
        selectedDays,
        selectedStatus,
      } = this.filters

      const matchers = []

      if (search) {
        matchers.push(this.matchText)
      }
      if (selectedProjects.length > 0) {
        matchers.push(this.matchProjects)
      }
      if (selectedCameras.length > 0) {
        matchers.push(this.matchCameras)
      }
      if (selectedUsers.length > 0) {
        matchers.push(this.matchUsers)
      }
      if (selectedDays.length > 0) {
        matchers.push(this.matchDays)
      }
      if (selectedStatus.length > 0) {
        matchers.push(this.matchStatus)
      }

      return matchers
    },
    currentProjects() {
      return this.progressPhotosList
        .map((item) => item.projects)
        .flat()
        .reduce((acc, project) => {
          if (project && !acc.some((p) => p.value === project.exid)) {
            acc.push({ text: project.name, value: project.exid })
          }

          return acc
        }, [])
    },

    currentCameras() {
      return this.progressPhotosList
        .map((item) =>
          item.cameras.map((camera) => ({
            text: camera.name,
            value: camera.exid,
          }))
        )
        .flat()
        .reduce((acc, camera) => {
          if (!acc.some((c) => c.value === camera.value)) {
            acc.push(camera)
          }

          return acc
        }, [])
    },

    currentUsers() {
      return this.progressPhotosList
        .map((item) => {
          if (item.config.recipients) {
            return item.config.recipients.map((recipient) => ({
              name: recipient.name,
              email: recipient.email,
            }))
          }

          return []
        })
        .flat()
        .reduce((acc, recipient) => {
          if (!acc.find((r) => r.email === recipient.email)) {
            acc.push(recipient)
          }

          return acc
        }, [])
        .sort((a, b) => a.email.localeCompare(b.email))
    },
  },
  watch: {
    "progressPhotoStore.progressPhotos"(val) {
      this.formatProgressPhotosToArray(val)
    },
    "progressPhotoStore.progressPhotoDialog"(visible) {
      if (this.progressPhotoStore.isEditDialog) {
        this.$analytics.saveEvent(
          AnalyticsEvent.ProgressPhotoToggleEditDialog,
          { visible }
        )
      } else {
        this.$analytics.saveEvent(
          AnalyticsEvent.ProgressPhotoToggleCreateDialog,
          { visible }
        )
      }
    },
  },
  async mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.days = weekDays
    this.formatProgressPhotosToArray(this.progressPhotoStore.progressPhotos)
    await this.progressPhotoStore.getProgressPhotos()
  },
  methods: {
    matchText(item) {
      const searchLower = this.filters.search.toLowerCase()

      return (
        item.cameraNames.join(", ").toLowerCase().includes(searchLower) ||
        item.projects.some((project) =>
          project.name.toLowerCase().includes(searchLower)
        ) ||
        (item.config.recipients &&
          item.config.recipients
            .map((recipient) => recipient.email)
            .join(", ")
            .toLowerCase()
            .includes(searchLower))
      )
    },
    matchProjects(item) {
      return item.projects.some(
        (project) =>
          project &&
          this.filters.selectedProjects.some((p) => p.value === project.exid)
      )
    },
    matchCameras(item) {
      const selectedCameraValues = new Set(
        this.filters.selectedCameras.map((camera) => camera.value)
      )

      return item.cameras.some((camera) =>
        selectedCameraValues.has(camera.exid)
      )
    },
    matchUsers(item) {
      if (!item.config.recipients) {
        return true
      }

      return item.config.recipients.some((recipient) =>
        this.filters.selectedUsers.includes(recipient.email)
      )
    },
    matchDays(item) {
      return item.notifyDays.some((day) =>
        this.filters.selectedDays.includes(day)
      )
    },
    matchStatus(item) {
      return this.filters.selectedStatus.includes(
        item.isPaused ? AutomationStatus.Paused : AutomationStatus.Active
      )
    },
    handleFiltersUpdate(filters) {
      this.filters = filters
    },
    async togglePauseAutomation(id, isPaused) {
      const confirmed =
        isPaused ||
        (await this.$confirmDialog.open({
          title: this.$t("content.are_you_sure"),
          message: this.$t("content.progress_photo.pause_confirmation"),
        }))
      if (confirmed) {
        this.$analytics.saveEvent(
          isPaused
            ? AnalyticsEvent.ProgressPhotoResume // User is trying to resume the automation
            : AnalyticsEvent.ProgressPhotoPause // User is trying to pause the automation
        )
        await this.progressPhotoStore.updatePauseStatus({
          id,
          isPaused: !isPaused,
        })
      }
    },
    isActiveDay(item, dayValue) {
      return item.notifyDays.includes(dayValue)
    },
    openCreateDialog() {
      this.progressPhotoStore.progressPhotoDialogType =
        ProgressPhotoDialogType.Create
      this.progressPhotoStore.progressPhotoDialog = true
    },
    openEditDialog(id) {
      this.progressPhotoStore.progressPhotoId = id
      this.progressPhotoStore.progressPhotoDialogType =
        ProgressPhotoDialogType.Edit
      this.progressPhotoStore.progressPhotoDialog = true
    },
    async openDeleteDialog(automationId) {
      if (
        await this.$confirmDialog.open({
          title: this.$t("content.are_you_sure"),
          message: this.$t("content.progress_photo.delete_confirmation"),
        })
      ) {
        this.$analytics.saveEvent(AnalyticsEvent.ProgressPhotoDelete)
        this.removeProgressPhoto(automationId)
      }
    },
    removeProgressPhoto(automationId) {
      this.progressPhotoStore.destroyProgressPhoto(automationId)
    },
    getProcoreProject(item) {
      const project = this.connectorStore.procoreUserProjects.find(
        (p) => item?.companyId === p.company.id && item?.projectId === p.id
      )
      if (!project) {
        return
      }

      return `${project.name} (${project.company.name})`
    },
    formatProgressPhotosToArray(data) {
      const stringToArray = (text) => text?.split(",") || ""
      this.progressPhotosList = data.reduce((acc, item) => {
        const projects = item.cameras.reduce((acc, { exid }) => {
          const cam = this.cameraStore.cameras.find(
            (camera) => camera.exid === exid
          )

          if (cam?.project && !acc.some((proj) => proj.id === cam.project.id)) {
            acc.push(cam.project)
          }

          return acc
        }, [])

        if (item.provider !== "email") {
          item.config.recipients = [
            {
              name: null,
              email: ` ${
                item.provider.charAt(0).toUpperCase() + item.provider.slice(1)
              }`,
            },
          ]
        }

        return [
          {
            ...item,
            createdAt: this.$moment
              .tz(item.createdAt, item.timeZone)
              .format("DD MMM YYYY HH:mm:ss"),
            cameras: item.cameras,
            cameraNames: item.cameras.map((camera) => camera.name),
            cameraThumbnail: this.getCameraThumbnail(item.cameras[0].exid),
            cameraCount: item.cameras.length,
            projects: projects.map((project) => {
              return {
                exid: project?.id,
                name: project?.name,
              }
            }),
            provider: item.provider,
            config: item.config,
            notifyDays: stringToArray(item.notifyDays),
          },
          ...acc,
        ]
      }, [])
    },
    getProviderName(provider) {
      if (!provider) {
        return ""
      }

      return `${provider.charAt(0).toUpperCase()}${provider.slice(1)}`
    },
    getCameraThumbnail(cameraExid) {
      const baseUrl = this.$config.public.apiURL
      const queryParams = `authorization=${this.accountStore.token}&view=true`

      return this.$imgproxy.get720pResizedImageUrl(
        `${baseUrl}/cameras/${cameraExid}/thumbnail?${queryParams}`
      )
    },
  },
}
