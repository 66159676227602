
export default {
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      settings: {
        fullscreen: false,
        download: false,
        zoom: false,
        playpause: false,
        refresh: 5,
      },
      refreshRateItems: [
        { text: "5 seconds", value: 5 },
        { text: "10 seconds", value: 10 },
        { text: "30 seconds", value: 30 },
        { text: "1 Minute", value: 60 },
      ],
    }
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        this.$emit("settings-changed", value)
      },
    },
  },
  mounted() {
    for (const key of Object.keys(this.widget.settings)) {
      if (this.widget.settings[key] !== undefined) {
        this.settings[key] = this.widget.settings[key]
      }
    }
  },
}
