
export default {
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      delayUnitItems: [
        { text: this.$t("content.minutes"), value: "minute" },
        { text: this.$t("content.hours"), value: "hour" },
        { text: this.$t("content.days"), value: "day" },
      ],
      settings: {
        hasDelay: false,
        delay: 5,
        delayUnit: "minute",
        darkMode: false,
        calendar: false,
      },
    }
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        this.$emit("settings-changed", value)
      },
    },
  },
  mounted() {
    for (const key of Object.keys(this.widget.settings)) {
      if (this.widget.settings[key] !== undefined) {
        this.settings[key] = this.widget.settings[key]
      }
    }
  },
}
